import React,{Component} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from '../../../libraries/api';
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from 'react-toastify';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../presentational/table-pagination-actions';
import moment from 'moment';
import {history} from '../../../shared/configure-store';
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CommonHelper from '../../../libraries/common-helper';
import AuthHelper from '../../../libraries/auth-helper';

require('dotenv').config();


const styleWidth = {
    minWidth: '100px',
};

const styleWidth1 = {
    minWidth: 'none',
};

const styleMax = {
    width: 'auto',
};

class ProjectsFilter extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingClearCaseStatus: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            showCaseStatusDialog: false,
            idDelete: '',
            currDelete: '',
            selectedCaseStatus: {},

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,
            commentSevenDays: '',
            isReport: false,

            title: '',
            case_number: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            instruction_date: '',
            client_id: '',
            typeId: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyStatus: '',
            surveyorId: '',
            statusBar: '',
            numberOfDays: '',
            client_type: '',
            adminId: '',
            surveyId: '',
            case_status_id: '',

            clients: [],
            project_status: [],
            surveyors: [],
            report_status: [],
            approval_status: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Projects';
        let url = this.props.location.search;
        let urlParams = queryString.parse(url);
        let pageTitle = '';
        let title = 'Projects';
        let status = '';
        let projectStatus = '';
        let isReport = false;

        if(urlParams.typeId === '647bbf15-c72a-4cdc-bace-464c5368e325') {
            title = 'Report';
            isReport = true;
        }else if(urlParams.typeId === '80e0e031-4932-466d-93c5-107ffd361218') {
            title = 'Approval';
        }

        if(urlParams.status === 'onp') {
            status = 'On Progress';
            projectStatus = 'On%20Progress';
        }else if(urlParams.status === 'fol') {
            status = 'Follow Up';
            projectStatus = 'Follow%20Up';
        }

        if (urlParams?.title) {
            pageTitle = urlParams.title;
        } else {
            pageTitle = title+' '+status;
        }

        let permissions = AuthHelper.getProfile()?.permissions;

        this.setState({
            permissions,
            queryParam : urlParams ? true : false,
            title : pageTitle,
            statusBar : title,
            case_number : urlParams.case_number ? urlParams.case_number : '',
            project_number : urlParams.project_number ? urlParams.project_number : '',
            ext_project_number : urlParams.ext_project_number ? urlParams.ext_project_number : '',
            vessel_name : urlParams.vessel_name ? urlParams.vessel_name : '',
            casualty: urlParams.casualty ? urlParams.casualty : '',
            instruction_date: urlParams.instruction_date ? urlParams.instruction_date : '',
            client_id: urlParams.client_id ? urlParams.client_id : '',
            typeId: urlParams.typeId ? urlParams.typeId : '',
            projectStatusReportId: urlParams.projectStatusReportId ? urlParams.projectStatusReportId : '',
            projectStatusApprovalId: urlParams.projectStatusApprovalId ? urlParams.projectStatusApprovalId : '',
            projectStatus: projectStatus,
            commentSevenDays: urlParams.commentSevenDays ? urlParams.commentSevenDays : '',
            numberOfDays: urlParams.numberOfDays ? urlParams.numberOfDays : '',
            surveyStatus: urlParams.survey ? urlParams.survey : '',
            case_status_id: urlParams.case_status_id ? urlParams.case_status_id : '',
            surveyorId: urlParams.surveyorId ? urlParams.surveyorId : '',
            adminId: urlParams.adminId ? urlParams.adminId : '',
            finalReviewerId: urlParams.finalReviewerId ? urlParams.finalReviewerId : '',
            isReport,
        },() => {
            this.__fetchData(false);
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps() {
        setTimeout(function () {
            let url = this.props.location.search;
            let urlParams = queryString.parse(url);
            let pageTitle = '';
            let title = 'Projects';
            let status = '';
            let projectStatus = '';
            let isReport = false;

            if(urlParams.typeId === '647bbf15-c72a-4cdc-bace-464c5368e325') {
                title = 'Report';
                isReport = true;
            }else if(urlParams.typeId === '80e0e031-4932-466d-93c5-107ffd361218') {
                title = 'Approval';
            }

            if(urlParams.status === 'onp') {
                status = 'On Progress';
                projectStatus = 'On%20Progress';
            }else if(urlParams.status === 'fol') {
                status = 'Follow Up';
                projectStatus = 'Follow%20Up';
            }

            if (urlParams?.title) {
                pageTitle = urlParams.title;
            } else {
                pageTitle = title+' '+status;
            }

            this.setState({
                queryParam : urlParams ? true : false,
                title : pageTitle,
                statusBar : title,
                case_number : urlParams.case_number ? urlParams.case_number : '',
                project_number : urlParams.project_number ? urlParams.project_number : '',
                ext_project_number : urlParams.ext_project_number ? urlParams.ext_project_number : '',
                vessel_name : urlParams.vessel_name ? urlParams.vessel_name : '',
                casualty: urlParams.casualty ? urlParams.casualty : '',
                instruction_date: urlParams.instruction_date ? urlParams.instruction_date : '',
                client_id: urlParams.client_id ? urlParams.client_id : '',
                typeId: urlParams.typeId ? urlParams.typeId : '',
                projectStatusReportId: urlParams.projectStatusReportId ? urlParams.projectStatusReportId : '',
                projectStatusApprovalId: urlParams.projectStatusApprovalId ? urlParams.projectStatusApprovalId : '',
                projectStatus: projectStatus,
                commentSevenDays: urlParams.commentSevenDays ? urlParams.commentSevenDays : '',
                numberOfDays: urlParams.numberOfDays ? urlParams.numberOfDays : '',
                surveyStatus: urlParams.survey ? urlParams.survey : '',
                case_status_id: urlParams.case_status_id ? urlParams.case_status_id : '',
                surveyorId: urlParams.surveyorId ? urlParams.surveyorId : '',
                adminId: urlParams.adminId ? urlParams.adminId : '',
                finalReviewerId: urlParams.finalReviewerId ? urlParams.finalReviewerId : '',
                isReport,
            },() => {
                this.__fetchData(false);
            });

        }.bind(this), 1);
    }

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            vessel_name : this.state.vessel_name,
            casualty: this.state.casualty,
            instruction_date: this.state.instruction_date,
            client_id: this.state.client_id,
            typeId: this.state.typeId ? this.state.typeId : '',
            projectStatusReportId: this.state.projectStatusReportId,
            projectStatusApprovalId: this.state.projectStatusApprovalId,
            projectStatus: this.state.projectStatus,
            commentSevenDays: this.state.commentSevenDays,
            numberOfDays: this.state.numberOfDays,
            survey_status_id: this.state.surveyStatus,
            case_status_id: this.state.case_status_id,
            surveyorId: this.state.surveyorId,
            adminId: this.state.adminId,
            finalReviewerId: this.state.finalReviewerId,
        };

        let orderBy = (this.state.orderBy === 'surveyors') ? '&orderByCustom=' : '&orderBy='; // special handler for surveyors column atm
        let sort = this.state.orderBy ? `${orderBy}${this.state.orderBy}&sortedBy=${this.state.sortedBy}` : '';

        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let route = '';

        if(this.state.showDialogFilter || this.state.queryParam) {
            route = '/projects?'+filter+'&limit='+this.state.perPage+'&page='+page+sort;
            if(search) {
                route = '/projects?limit='+this.state.perPage+sort+search;
            }
        }else if(search) {
            route = '/projects?limit='+this.state.perPage+sort+search;
        }else {
            route = '/projects?limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {
                if (resp.data) {
                    this.setState({
                        rows: resp.data,
                        perPage: resp.meta.pagination.per_page,
                        currentPage: resp.meta.pagination.current_page,
                        currentPageTable: resp.meta.pagination.current_page - 1,
                        total: resp.meta.pagination.total,
                        loading: false,
                        loadingButton: false,
                        showDialogFilter: false,
                        isEdit: resp.meta.custom.isEdit,
                        isDelete: resp.meta.custom.isDelete,
                        isCreate: resp.meta.custom.isCreate,
                    });
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleConfirmClearCaseStatus = (row) => {
        this.setState({
            selectedCaseStatus: { projectId: row?.id, caseStatus: row?.caseStatus },
            showCaseStatusDialog: true,
        });
    };

    handleCloseCaseStatusDialog = () => {
        this.setState({
            loadingClearCaseStatus: false,
            showCaseStatusDialog: false,
            selectedCaseStatus: {},
        });
    }

    handleClearCaseStatus = () => {
        this.setState({ loadingClearCaseStatus: true });
           
        let params = {
            case_status_id: this.state.selectedCaseStatus?.caseStatus?.id,
            delete_case_status: this.state.selectedCaseStatus?.caseStatus?.id,
        };

        Api.patch('/projects/'+this.state.selectedCaseStatus?.projectId, params, false).then(resp => {
            this.setState({
                loadingClearCaseStatus: false,
                showCaseStatusDialog: false,
                selectedCaseStatus: {},
            }, () => {
                this.__fetchData();
                this.showMessage(true, 'Case Status cleared');
            })
        }).catch(err => {
            this.showMessage(false, 'Failed to update data');
        });
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenFilter = () => {
        this.setState({
            showDialogFilter :true,
        })
    };

    handleCloseFilter = () => {
        this.setState({
            showDialogFilter :false,
        })
    };

    handleSubmitFilter = () => {

        this.__fetchData(false);

    };

    handleResetFilter = () => {

        this.setState({
            case_number : '',
            project_number : '',
            ext_project_number : '',
            vessel_name : '',
            casualty: '',
            instruction_date: '',
            client_id: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyorId: '',
            surveyStatus: '',
            case_status_id: '',
        },() => {
            this.__fetchData(false);
        });

    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/projects/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'on progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    filterCaseStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Waiting for supporting data':
                text = 'yellow';
                break;
            case 'URGENT':
                text = 'red';
                break;
            case 'Vessel being salvage':
                text = 'blue';
                break;
            case 'Vessel being repair':
                text = 'purple';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    handleExport = () => {

        this._isMounted = true;

        this.setState({
            loadingButton: true,
        })

        let page = this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            vessel_name : this.state.vessel_name,
            client_type : this.state.client_type,
            casualty: this.state.casualty,
            instruction_date: this.state.instruction_date,
            client_id: this.state.client_id ? this.state.client_id.value : '',
            typeId: this.state.typeId ? this.state.typeId : '',
            remarks: this.state.remarks ? this.state.remarks : '',
            projectStatusReportId: this.state.projectStatusReportId,
            projectStatusApprovalId: this.state.projectStatusApprovalId,
            projectStatus: this.state.projectStatus,
            surveyorId: this.state.surveyorId,
            adminId: this.state.adminId,
            survey_status_id: this.state.surveyId,
            case_status_id: this.state.case_status_id,
        };

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let route = '';

        if(this.state.showDialogFilter || this.state.queryParam) {
            route = '/invoices?export=operation&'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        }else if(search) {
            route = '/invoices?export=operation&limit='+this.state.perPage+sort+search;
        }else {
            route = '/invoices?export=operation&limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingButton: false,
            })
        });

    };

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>{this.state.title}</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Projects</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-12 mt-3 px-lg-5'>
                    <div className='table-wrapper'>
                        <div className='row align-items-center mb-md-3'>
                            <div className='col-md-8'>
                                <TextField
                                    id='input-with-icon-textfield'
                                    variant='outlined'
                                    className='search-field'
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder='Search here'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='start'>
                                                <IconButton
                                                    aria-label='Search click'
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className='fas fa-search'> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className='col-md-4 text-md-right'>
                                {
                                    CommonHelper.checkPermission(this.state.permissions, 'export-projects') && (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mb-3 mb-md-0'
                                            onClick={() => this.handleExport()}
                                        >
                                            Export { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                        <TableContainer>
                            <Table className='table-list' size='small' >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styleWidth}>Action</TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('case_number')} >Case No
                                            {this.state.orderBy === 'case_number' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell>Underwritter</TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('type_id')} >Project Type
                                            {this.state.orderBy === 'type_id' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('vessel_name')} >Vessel
                                            {this.state.orderBy === 'vessel_name' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('casualty')} >Casualties
                                            {this.state.orderBy === 'casualty' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        {(this.state.statusBar === 'Approval') &&
                                            <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('instruction_date')}>Instruction Date
                                                {this.state.orderBy === 'instruction_date' ?
                                                    <span className='icon-sort'>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                    </span> :
                                                    <span className='icon-sort'>
                                                        <i className='fas fa-long-arrow-alt-up'> </i>
                                                        <i className='fas fa-long-arrow-alt-down'> </i>
                                                    </span>}
                                            </TableCell>
                                        }
                                        {(this.state.statusBar === 'Approval') &&
                                            <TableCell style={styleWidth} className='clickable'>Approval Category</TableCell>
                                        }
                                        {(this.state.statusBar === 'Report' || this.state.surveyStatus !== '') &&
                                            <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('date_survey_status')} >Survey Status
                                                {this.state.orderBy === 'date_survey_status' ?
                                                    <span className='icon-sort'>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                    </span> :
                                                    <span className='icon-sort'>
                                                        <i className='fas fa-long-arrow-alt-up'> </i>
                                                        <i className='fas fa-long-arrow-alt-down'> </i>
                                                    </span>}
                                            </TableCell>
                                        }
                                        {(this.state.statusBar === 'Report' || this.state.surveyStatus !== '') &&
                                            <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('surveyors')} >Surveyor
                                            {this.state.orderBy === 'surveyors' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        }
                                        <TableCell style={styleWidth} className='clickable' onClick={() => this.handleSort('date_status')} >Report/Approval Status
                                            {this.state.orderBy === 'date_status' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth} className='clickable'>Project Status</TableCell>
                                        <TableCell style={styleWidth} className='clickable'>Case Status</TableCell>
                                        {/* {
                                            this.state.isReport && (
                                                <>
                                                    <TableCell style={styleWidth}>Action</TableCell>
                                                    <TableCell style={styleWidth} className='clickable'>Case Status</TableCell>
                                                </>
                                            )
                                        } */}
                                        <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('updated_at')} >Last Status
                                            {
                                                this.state.orderBy === 'updated_at' ?
                                                (
                                                    <span className='icon-sort'>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                        <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                    </span>
                                                ) : 
                                                (
                                                    <span className='icon-sort'>
                                                        <i className='fas fa-long-arrow-alt-up'> </i>
                                                        <i className='fas fa-long-arrow-alt-down'> </i>
                                                    </span>
                                                )
                                            }
                                        </TableCell>
                                        <TableCell className='clickable' >Days</TableCell>
                                        {/* {!this.state.isReport && <TableCell className='clickable'>Action</TableCell>} */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={13} align='center' className='py-5'>
                                                <CircularProgress color='primary'  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={this.state.isReport ? 13 : 12} style={{ textAlign: 'center' }}>No project</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map((row, idx) => (
                                                <TableRow
                                                    key={idx}
                                                    selected={row.selected}
                                                >
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-eye'> </i>
                                                            </Link>
                                                            <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-edit'> </i>
                                                            </Link>
                                                            <button className='btn-icon' onClick={() => this.handleConfirmClearCaseStatus(row)}>
                                                                <i className='fas fa-times'> </i>
                                                            </button>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={'/projects/detail/' + row.id} target='_blank'>
                                                            {row.caseNumber === null ? '' : row.caseNumber}{row.projectNumber === null ? '' : '['+row.projectNumber+']'}
                                                        </Link>
                                                    </TableCell>
                                                    {this.state.statusBar === 'Report' ?
                                                        <TableCell>
                                                            {row.underWriters &&
                                                            row.underWriters.map(row => (
                                                                <small>{row.name}, </small>
                                                            ))
                                                            }
                                                        </TableCell> :
                                                        <TableCell>
                                                            {row.adjuster && row.adjuster.name}
                                                        </TableCell>}
                                                    <TableCell>{row.type === null ? '' : row.type.name}{row.detailType === null ? '' : '['+row.detailType.name+']'}</TableCell>
                                                    <TableCell>{row.vesselName === null ? '' : row.vesselName}</TableCell>
                                                    <TableCell>{row.casualty === null ? '' : row.casualty}</TableCell>
                                                    {(this.state.statusBar === 'Approval') &&
                                                        <TableCell>{row.instructionDate === null ? '' : moment(row.instructionDate).format('DD MMM YYYY')}</TableCell>
                                                    }
                                                    {(this.state.statusBar === 'Approval') &&
                                                        <TableCell>{row?.approvalCategory?.name ? row.approvalCategory.name : ''}</TableCell>
                                                    }
                                                    {this.state.statusBar === 'Report' &&
                                                    <TableCell>
                                                        {row.surveyStatus &&
                                                        <small>
                                                            <span
                                                                className={'label-status label-' + this.filterStatus(row.surveyStatus !== null && row.surveyStatus.project_status)}>{row.surveyStatus === null ? '' : row.surveyStatus.name}</span>
                                                            {row.dateSurveyStatus === null ? '' : moment(row.dateSurveyStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    }
                                                    {(this.state.statusBar === 'Report' || this.state.surveyStatus !== '') &&
                                                        <TableCell>
                                                            {row.surveyor && row.surveyor.length > 0 ? row.surveyor.map((item, idx) => (
                                                                <>
                                                                    <small>{item?.name || ''}{row.surveyor.length == idx+1 ? '' : ', '}</small>
                                                                    {row.surveyor.length == idx+1 ? '' : <br/>}
                                                                </>
                                                            )) : ''}
                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        {(row.projectStatus && (row.surveyStatus && row.surveyStatus.name === 'Survey Done') && row.type.name === 'Report') &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatus !== null && row.projectStatus.project_status)}>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                            {row.dateStatus === null ? '' : moment(row.dateStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                        {(row.projectStatus && row.type.name === 'Approval') &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatus !== null && row.projectStatus.project_status)}>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                            {row.dateStatus === null ? '' : moment(row.dateStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.projectStatusBar &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatusBar !== null && row.projectStatusBar.project_status)}>{row.projectStatusBar === null ? '' : row.projectStatusBar.project_status}</span>
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.caseStatus &&
                                                            <small>
                                                                <span className={'label-status label-'+this.filterCaseStatus(row.caseStatus !== null && row.caseStatus.name)}>{row.caseStatus === null ? '' : row.caseStatus.name}</span>
                                                            </small>
                                                        }
                                                    </TableCell>
                                                    {/* {this.state.isReport && (
                                                        <>
                                                            // Action for Report
                                                            <TableCell>
                                                                <div className="d-flex">
                                                                    <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                                        <i className='fas fa-eye'> </i>
                                                                    </Link>
                                                                    <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                                        <i className='fas fa-edit'> </i>
                                                                    </Link>
                                                                    <button className='btn-icon' onClick={() => this.handleConfirmClearCaseStatus(row)}>
                                                                        <i className='fas fa-times'> </i>
                                                                    </button>
                                                                </div>
                                                            </TableCell>
                                                            // Case Status
                                                            <TableCell>
                                                                {row.caseStatus &&
                                                                    <small>
                                                                        <span className={'label-status label-'+this.filterCaseStatus(row.caseStatus !== null && row.caseStatus.name)}>{row.caseStatus === null ? '' : row.caseStatus.name}</span>
                                                                    </small>
                                                                }
                                                            </TableCell>
                                                        </>
                                                    )} */}
                                                    <TableCell>
                                                        {(row.projectStatus && row.projectStatus.project_status !== 'Finish') ?
                                                            <span className={moment().diff(row.comment.length > 0 && row.comment[0].updated_at, 'day') > 7 ? 'alert' : ''}>
                                                                {row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)}
                                                            </span>
                                                            : row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)
                                                        }
                                                        <small className='d-block'>{row.comment === null ? '' : row.comment.length > 0 && moment(row.comment[0].updated_at).format('DD MMM YYYY')}</small>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.numberOfDays > 30 ? <span className='danger'>{row.numberOfDays}</span> : row.numberOfDays }
                                                    </TableCell>
                                                    {/* {!this.state.isReport && (
                                                        <>
                                                            // Action for Not Report
                                                            <TableCell>
                                                                <div className="d-flex">
                                                                    <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                                        <i className='fas fa-eye'> </i>
                                                                    </Link>
                                                                    <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                                        <i className='fas fa-edit'> </i>
                                                                    </Link>
                                                                    <button className='btn-icon' onClick={() => this.handleOpen(row)}>
                                                                        <i className='fas fa-trash-alt'> </i>
                                                                    </button>
                                                                </div>
                                                            </TableCell>
                                                        </>
                                                    )} */}
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component='div'
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showCaseStatusDialog}
                    onClose={this.handleCloseCaseStatusDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Konfirmasi</DialogTitle>
                    <DialogContent>
                        <p>Anda yakin akan menghapus Case Status ini ?</p>
                    </DialogContent>
                    <DialogActions className="p-4">
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleCloseCaseStatusDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="danger"
                            onClick={this.handleClearCaseStatus}
                            disabled={this.state.loadingClearCaseStatus && 'disabled' }
                        >
                            Confirm { this.state.loadingClearCaseStatus && <i className="fa fa-spinner fa-spin"> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={false} />

            </div>
        )
    }
}

export default ProjectsFilter;
