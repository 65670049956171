import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import InstructionPerYearTab from './components/InstructionPerYearTab';
import InstructionComparison3YearsTab from './components/InstructionComparison3YearsTab';
import InstructionPerCaseTypeTab from './components/InstructionPerCaseTypeTab';
import InstructionPerYearByCaseType from './components/InstructionPerYearByCaseType';

function TabPanel(props) {
  const { children, value, title, index, ...other } = props;

  return (
    <>
      {title && <h2 className="page-title ml-4">{title}</h2>}
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '150px',
  },
  tabContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ProjectCharts() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="row main-content">
      <div className="col-12 px-lg-5">
        <h2 className={`page-title ${classes.title}`}>Project Charts</h2>
        <nav aria-label="breadcrumb" className={classes.breadcrumb}>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Charts
            </li>
          </ol>
        </nav>

        <div className={classes.root}>
          {/* Vertical Tabs */}
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab label="New Instructions Per Year" {...a11yProps(0)} />
            <Tab label="New Instructions Comparison 3 Years" {...a11yProps(1)} />
            <Tab label="New Instructions Per Case Type" {...a11yProps(2)} />
            <Tab label="New Instructions Per Year By Case Type" {...a11yProps(3)} />
          </Tabs>

          {/* Tab Panels */}
          <div className={classes.tabContent}>
            {value === 0 && (
              <TabPanel value={value} index={0} title="New Instructions Per Year">
                <InstructionPerYearTab />
              </TabPanel>
            )}
            {value === 1 && (
              <TabPanel value={value} index={1} title="New Instructions Comparison 3 Years">
                <InstructionComparison3YearsTab />
              </TabPanel>
            )}
            {value === 2 && (
              <TabPanel value={value} index={2} title="New Instructions Per Case Type">
                <InstructionPerCaseTypeTab />
              </TabPanel>
            )}
            {value === 3 && (
              <TabPanel value={value} index={3} title="New Instructions Per Year By Case Type">
                <InstructionPerYearByCaseType />
              </TabPanel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

