/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

import Api from '../../../../../libraries/api';


export default function InstructionPerYearTab(props) {
    const { children, value, index, ...other } = props;
    const [yearFrom, setYearFrom] = useState(2002);
    const [yearTo, setYearTo] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);

    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = 2002; i <= currentYear; i++) {
        years.push(i);
    }

    const fetchData = () => {
        setLoading(true);

        Api.get(`/charts/report/yearly_new_instructions?start_year=${yearFrom}&end_year=${yearTo}`)
            .then(resp => {
                if (resp.data) {
                    const data = resp.data;
                    const labels = Object.keys(data).filter(year => year >= yearFrom && year <= yearTo);
                    const values = labels.map(year => data[year]);

                    setChartData({
                        labels,
                        datasets: [
                            {
                                label: 'New Instructions',
                                data: values,
                                backgroundColor: '#5a7dfd',
                                borderColor: '#5a7dfd',
                                borderWidth: 1,
                                maxBarThickness: 50,
                                barPercentage: 0.5,
                            },
                        ],
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeYearFrom = e => setYearFrom(e.target.value);
    const handleChangeYearTo = e => setYearTo(e.target.value);

    useEffect(() => {
        if (yearFrom && yearTo && yearFrom <= yearTo) fetchData();
    }, [yearFrom, yearTo]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    offset: false,
                },
            },
            y: {
                grid: {
                    offset: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        },
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className='table-wrapper'>
                    <div className='row align-items-center justify-content-end mb-md-3'>
                        <div className="col-md-6 col-lg-4 mr-auto">
                            <div className="form-group">
                                <label>Year From</label>
                                <TextField
                                    variant="outlined"
                                    select
                                    id="year"
                                    name="year"
                                    onChange={handleChangeYearFrom}
                                    value={yearFrom}
                                    fullWidth
                                >
                                    {years.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                </TextField>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mr-auto">
                            <div className="form-group">
                                <label>Year To</label>
                                <TextField
                                    variant="outlined"
                                    select
                                    id="year"
                                    name="year"
                                    onChange={handleChangeYearTo}
                                    value={yearTo}
                                    fullWidth
                                >
                                    {years.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-end mb-md-3">
                        <div className="col-12">
                            <div style={{ minHeight: '500px', height: '60vh' }}>
                                {chartData && <Bar data={chartData} options={options} />}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
