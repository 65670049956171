import React,{Component} from 'react';
import cancel from "../../../images/cancel.png";
import Skeleton from '@material-ui/lab/Skeleton';
import AuthHelper from "../../../libraries/auth-helper";
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import user from '../../../images/default.png';
import {Link, withRouter} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from 'simple-react-validator';
import Api from '../../../libraries/api';
import StatusProject from '../../../images/status-project.png';
import StatusFinance from '../../../images/status-finance.png';
import editImage from '../../../images/edit.png';
import StatusOS from '../../../images/status-os.png';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import NumberFormat from 'react-number-format';
import ErrorHandling from '../../../libraries/error-handling';
import {toast, ToastContainer} from 'react-toastify';
import {history} from '../../../shared/configure-store';
import _ from 'lodash';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import {bindActionCreators} from 'redux';
import {RootActions} from '../../../shared/root-action';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PROJECT_TYPE_IDS } from '../../../constants/global';
import CommonHelper from "../../../libraries/common-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorProject = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorSurvey = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorComment = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorCommentClient = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorProjectSummary = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorSummaryOfCost = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorEx = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});


class ProjectsDetail extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingCommentList: false,
            loadingCommentListClient: false,
            loadingButton: false,
            loadingProject: false,
            loadingCase: false,
            loadingSurvey: false,
            loadingComment: false,
            loadingProjectSummary: false,
            loadingSummaryOfCost: false,
            loadingSave: false,
            errors: {},

            permissions: [],

            showDialog: false,
            showDialogDelete: false,
            showDialogRevert: false,

            editStatus: false,
            editStatusCase: false,
            editSurvey: false,
            queryParam: false,

            underWriters: [],
            broker: [],
            adjuster: [],
            shipowner: [],
            clients: [],
            instructor: '',

            survey_status: [],
            report_status: [],
            case_status: [],
            approval_status: [],
            project_status: '',
            finance_status: '',
            project_status_id: '',
            project_status_name: '',
            date_status: null,
            comment: '',
            case_status_id: '',
            case_status_name: '',
            client_comment: '',

            survey_status_id: '',
            survey_status_name: '',
            survey_comment: '',
            survey_remarks: '',
            date_survey_status: '',

            case_number: '',
            project_number: '',
            ext_project_number: '',
            case_type: '',
            type: '',
            type_id: '',
            detail_type: '',
            vessel_name: '',
            vesselType: null,
            vesselTypeData: '',
            vessel_grt: '',
            vessel_yob: '',
            engine_particular_hp: '',
            underwriters: [],
            casualty: '',
            casualtyType: null,
            place_of_accident: '',
            instruction_date: null,
            date_of_accident: null,
            instruction_by: '',
            pic_case: '',
            currency: '',
            estimation_cost: '',
            project_summary: '',
            place_of_repair: '',
            summary_of_cost: '',

            surveyor: [],
            admins: [],
            finalReviewer: [],
            under: '',
            soft_copy_data: 0,
            hard_copy_report: 0,
            hard_copy_invoice: 0,

            user_comment: '',
            user_id_current: '',

            comment_list: [],
            comment_list_client: [],

            expense: [],
            total_in: 0,
            total_out: 0,
            saldo: 0,
            survey_hours: 0,
            survey_days: 0,

            top_project_status: '',
            top_finance_status: '',
            top_os: 0,

            photo: null,
            image: null,
            typeDelete: '',


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditStatus = this.handleEditStatus.bind(this);
        this.handleEditStatusCase = this.handleEditStatusCase.bind(this);
        this.handleEditStatusSurvey = this.handleEditStatusSurvey.bind(this);
        this.handleCancelEditStatus = this.handleCancelEditStatus.bind(this);
        this.handleCancelEditStatusSurvey = this.handleCancelEditStatusSurvey.bind(this);
        this.handleCancelEditStatusCase = this.handleCancelEditStatusCase.bind(this);
        this.handleSubmitStatus = this.handleSubmitStatus.bind(this);
        this.handleSubmitStatusCase = this.handleSubmitStatusCase.bind(this);
        this.handleSubmitStatusSurvey = this.handleSubmitStatusSurvey.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.handleCommentClient = this.handleCommentClient.bind(this);
        this.handleNewExpense = this.handleNewExpense.bind(this);
        this.handleNewAdvance = this.handleNewAdvance.bind(this);
        this.handleSaveExpense = this.handleSaveExpense.bind(this);
        this.handleDelExp = this.handleDelExp.bind(this);
        this.handleChangeEx = this.handleChangeEx.bind(this);
        this.handleOpenDeleteComment = this.handleOpenDeleteComment.bind(this);

    }

    componentDidMount() {
        document.title = 'Abadi - Detail Project';

        this.setState({
            loading: true,
            loadingCommentList: true,
            permissions: AuthHelper.getProfile().permissions,
        });

        this.__fetch();

        Api.get('/clients?limit=0').then(resp => {
            if (resp.data) this.setState({ clients: resp.data });
        });

        Api.get('/project/status?limit=0').then(resp => {
            if (resp.data) {
                let report = [];
                let approval = [];

                if(resp.data.length > 0) {
                    _.forEach(resp.data, function(v,i) {
                        if (v.type === 'Report' && v.project_status !== 'Deleted') {
                            report.push(v);
                        } else if (v.type === 'Approval' && v.project_status !== 'Deleted') {
                            approval.push(v);
                        }
                    });
                }

                // this.setState({ report_status: report, approval_status: approval });

                const mapProjectReportStatusOrder = CommonHelper.mapProjectReportStatusOrder(report);
                const mapProjectApprovalStatusOrder = CommonHelper.mapProjectApprovalStatusOrder(approval);

                this.setState({ report_status: mapProjectReportStatusOrder, approval_status: mapProjectApprovalStatusOrder });
            }
        });

        Api.get('/survey/status?limit=0').then(resp => {
            if (resp.data) this.setState({ survey_status: resp.data });
        });

        this.__fetchExpenses();
        this.__fetchComment()
    }

    __fetchComment = () => {
        const id = this.props.match.params.id;
        Api.get('/comments?search=projects_id:'+id+'&limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    comment_list: resp.data,
                    loadingCommentList: false
                });

                if (this.props.profile_state) {
                    this.setState({ user_id_current: this.props.profile_state.id });
                }
            }
        }).catch(err => {
            this.setState({ loadingCommentList: false });
        });

        Api.get('/client-comments?search=projects_id:'+id+'&limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    comment_list_client: resp.data,
                    loadingCommentListClient: false
                });

                if (this.props.profile_state) {
                    this.setState({
                        user_id_current: this.props.profile_state.id,
                    })
                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loadingCommentList: false });
        });

    };

    __fetch = () => {
        const id = this.props.match.params.id;
        Api.get('/projects/'+id).then(resp => {
            if (resp.data) {
                let data = resp.data;

                this.setState({
                    loading: false,
                    case_number: data.caseNumber,
                    project_number: data.projectNumber,
                    ext_project_number: data.extProjectNumber,
                    case_type: data.caseType,
                    type: data.type ? data.type.name : '',
                    type_id: data.type ? data.type.id : '',
                    detail_type: data.detailType ? data.detailType.name :'',
                    detail_type_id: data.detailType ? data.detailType.id :'',
                    vessel_name: data.vesselName,
                    vesselType: data?.vesselType,
                    vesselTypeData: data?.vesselTypeData,
                    vessel_grt: data.vesselGrt,
                    vessel_yob: data.vesselYob,
                    engine_particular_hp: data?.engineParticularHp,
                    broker: data.broker ? data.broker.name : '',
                    adjuster: data.adjuster ? data.adjuster.name : '',
                    shipowner: data.shipowner ? data.shipowner.name : '',
                    instructor: data?.instructor?.name || '',
                    underwriters: data.underWriters,
                    casualty: data.casualty,
                    casualtyType: data.casualtyType,
                    place_of_accident: data.placeOfAccident,
                    instruction_date: data.instructionDate || null,
                    approval_category: data?.approvalCategory,
                    date_of_accident: data.dateOfAccident || null,
                    instruction_by: data.instructionBy,
                    pic_case: data.picCase,
                    currency: data.currency,
                    hard_copy_invoice: data.hardCopyInvoice,
                    hard_copy_report: data.hardCopyReport,
                    soft_copy_data: data.softCopyData,
                    estimation_cost: data.estimationCost && data?.estimationCost !== "null" ? data.estimationCost : '',
                    total_account_submitted: data.total_account_submitted,
                    total_account_approved: data.total_account_approved,
                    total_account_approved_for_cost: data.total_account_approved_for_cost,
                    total_account_approved_with_comment: data.total_account_approved_with_comment,
                    total_account_not_approved: data.total_account_not_approved,
                    residual_scrap_value: data.residual_scrap_value,
                    repairs: data.repairs,
                    files: data.files,
                    project_summary: data.projectSummary,
                    place_of_repair: data.place_of_repair,
                    summary_of_cost: data.summary_of_cost,

                    surveyor: data.surveyor,
                    admins: data.admins,
                    finalReviewer: data.finalReviewer,

                    project_status: data.projectStatus,
                    project_survey_status: data.surveyStatus,
                    finance_status: data.financeStatus,

                    project_status_id: data.projectStatus ? data.projectStatus.id : '',
                    project_status_name: data.projectStatus ? data.projectStatus.name : '',
                    case_status_id: data.caseStatus ? data.caseStatus.id : '',
                    case_status_name: data.caseStatus ? data.caseStatus.name : '',
                    date_status: data.dateStatus,
                    comment: data.comment ? data.projectStatusComment : '',

                    survey_status_name: data.surveyStatus ? data.surveyStatus.name : '',
                    survey_status_id: data.surveyStatus ? data.surveyStatus.id : '',
                    survey_comment: data.surveyComment ? data.surveyComment : '',
                    survey_remarks: data.surveyRemarks ? data.surveyRemarks : '',
                    date_survey_status: data.dateSurveyStatus || null,

                    top_project_status: data.projectStatusBar ? data.projectStatusBar.project_status : '',
                    top_finance_status: data.financeStatus ? data.financeStatus : '',
                    top_os: data.totalBalance ? data.totalBalance : 0,
                    survey_hours: data.totalSurveyHour ? data.totalSurveyHour : 0,
                    survey_days: data.survey_days ? data.survey_days : 0,
                }, () => {
                    this.__fetchCaseStatus(data.type.id || null);
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading: false });
        });
    };

    __fetchExpenses = () => {
        const id = this.props.match.params.id;

        Api.get('/expenses?search=projects_id:'+id).then(resp => {
            if (resp.data) {
                this.setState({
                    expense: resp.data,
                    total_in: resp.meta ? resp.meta.custom.totalIn : 0,
                    total_out: resp.meta ? resp.meta.custom.totalOut : 0,
                    saldo: resp.meta ? resp.meta.custom.saldo : 0,
                });
            }
        });
    };

    __fetchCaseStatus = (projectTypeId = null) => {
        Api.get('/cases-status?limit=0').then(resp => {
            if (resp.data) {
                let case_status = [];

                if (resp.data.length > 0) {
                    _.forEach(resp.data, function(v,i){
                        if (!projectTypeId) {
                            case_status.push(v);
                        } else if (projectTypeId === PROJECT_TYPE_IDS.approval) {
                            if (v.type.includes('Approval')) case_status.push(v);
                        } else if (projectTypeId === PROJECT_TYPE_IDS.report) {
                            if (v.type.includes('Report')) case_status.push(v);
                        }
                    });
                }

                this.setState({ case_status });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    handleDate (date, prop){
        this.setState({ [prop]: date });
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let temp = [];
        if(this.state.underwriter.length > 0) {
            _.forEach(this.state.underwriter, function(v){ temp.push(v.id) });
        }

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            case_type : this.state.case_type,
            type_id : this.state.type_id,
            detail_type_id : this.state.detail_type_id,
            vessel_name : this.state.vessel_name,
            vessel_type_id : this.state.vessel_type_id,
            vessel_grt : this.state.vessel_grt,
            vessel_yob : this.state.vessel_yob,
            engine_particular_hp : this.state.engine_particular_hp,
            broker : this.state.broker,
            adjuster : this.state.adjuster,
            shipowner : this.state.shipowner,
            instructor : this.state.instructor,
            underwriter : temp,
            casualty: this.state.casualty,
            casualty_type_id: this.state.casualty_type_id,
            place_of_accident: this.state.place_of_accident,
            instruction_date: this.state.instruction_date,
            date_of_accident: this.state.date_of_accident,
            instruction_by: this.state.instruction_by,
            pic_case: this.state.pic_case,
            currency: this.state.currency,
            currency_rate: this.state.currency_rate,
            hard_copy_invoice: this.state.hard_copy_invoice,
            hard_copy_report: this.state.hard_copy_report,
            soft_copy_data: this.state.soft_copy_data,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({ loadingButton: false });
            this.showMessage(true, 'Project updated');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else {
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    filterName = (id) => {
        let all = this.state.clients.filter((item) => item.id === id);
        if(all.length > 0)
            return all[0].name;
    };

    filterPermission = (name) => {
        let all = this.state.permissions.filter((item) => item === name);
        if (all.length > 0)  return true;
    };

    handleGo  = (link) => {
        history.push(link);
    };

    handleEditStatus = () => {
        let date = Date.now();
        this.setState({ editStatus: true, date_status: date });
    };

    handleEditStatusCase = () => {
        this.setState({
            editStatusCase: true,
            case_date_status: Date.now(),
        });
    };

    handleEditStatusSurvey = () => {
        this.setState({ editSurvey: true });
    };

    handleCancelEditStatusCase = () => {
        this.setState({ editStatusCase: false });
    };

    handleCancelEditStatusSurvey = () => {
        this.setState({ editSurvey: false });
    };

    handleCancelEditStatus = () => {
        this.setState({ editStatus: false });
    };

    handleSubmitStatus = (e) => {
        e.preventDefault();

        if (!validatorProject.allValid()) {
            this.setState({ errors: validatorProject.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingProject: true });

        let params = {
            date_status: moment(this.state.date_status).format('YYYY-MM-DD'),
            project_status_id: this.state.project_status_id,
            comment: this.state.comment,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingProject: false,
                editStatus: false,
                project_status_id: resp.data.projectStatus ? resp.data.projectStatus.id : '',
                project_status_name: resp.data.projectStatus ? resp.data.projectStatus.name : '',
                date_status: resp.data.dateStatus,
                comment: resp.data.projectStatusComment,
            }, () => {
                let param = {
                    projects_id: this.props.match.params.id,
                    user_id: this.state.user_id_current,
                    type: 'comment',
                    comment: resp.data.projectStatus.name+' dilakukan pada tanggal '+resp.data.dateStatus,
                };

                Api.post('/comments', param, false).then(resp => {
                    this.__fetchComment();
                    this.__fetch();
                    this.showMessage(true, 'Status updated');
                }).catch(err => {
                    this.setState(
                        { errors: err.errors, loadingComment: false },
                        () =>  this.__fetchComment()
                    );
                    this.showMessage(false, 'Invalid format data');
                });
            })
        }).catch(err => {
            this.setState({ errors: err.errors, loadingProject: false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleSubmitStatusCase = (e) => {
        e.preventDefault();

        if (!validatorProject.allValid()) {
            this.setState({ errors: validatorProject.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingCase: true });

        let params = {
            case_status_id: this.state.case_status_id,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingCase: false,
                editStatusCase: false,
                case_status_id: resp.data.caseStatus ? resp.data.caseStatus.id : '',
                case_status_name: resp.data.caseStatus ? resp.data.caseStatus.name : '',
            }, () => {
                this.__fetch();
                this.showMessage(true, 'Status updated');
            })
        }).catch(err => {
            this.setState({ errors: err.errors, loadingCase: false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleClearCaseStatus = (e) => {
        e.preventDefault();

        let params = {
            case_status_id: this.state.case_status_id,
            delete_case_status: this.state.case_status_id,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingCase: false,
                editStatusCase: false,
                case_status_id: '',
                case_status_name: '',
            }, () => {
                this.__fetch();
                this.showMessage(true, 'Status updated');
            })
        }).catch(err => {
            this.setState({ errors: err.errors, loadingCase: false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleSubmitStatusSurvey = (e) => {
        e.preventDefault();

        if (!validatorSurvey.allValid()) {
            this.setState({ errors: validatorSurvey.getErrorMessages() });
            return false;
        }

        this.setState({  errors: {}, loadingSurvey: true });

        let params = {
            date_survey_status: moment(this.state.date_survey_status).format('YYYY-MM-DD'),
            survey_status_id: this.state.survey_status_id,
            survey_comment: this.state.survey_comment,
            survey_remarks: this.state.survey_remarks,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingSurvey: false,
                    editSurvey: false,
                    survey_status_name: resp.data.surveyStatus ? resp.data.surveyStatus.name : '',
                    survey_status_id: resp.data.surveyStatus ? resp.data.surveyStatus.id : '',
                    survey_comment: resp.data.surveyComment ? resp.data.surveyComment : '',
                    survey_remarks: resp.data.surveyRemarks ? resp.data.surveyRemarks : '',
                    date_survey_status: resp.data.dateSurveyStatus,
                }, () =>  {

                let param = {
                    projects_id: this.props.match.params.id,
                    user_id: this.state.user_id_current,
                    type: 'comment',
                    comment: resp.data.surveyStatus.name+' dilakukan pada tanggal '+resp.data.dateSurveyStatus,
                };

                Api.post('/comments', param, false).then(resp => {

                    if(resp.data)
                    this.__fetchComment();
                    this.__fetch();
                    this.showMessage(true, 'Status updated');
                }).catch(err => {
                    this.setState({
                        errors: err.errors,
                        loadingComment: false
                    }, () => {
                        this.__fetchComment();
                    });

                    this.showMessage(false, 'Invalid format data');
                });
            });

        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else {
                this.setState({ errors: err.errors, loadingSurvey: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    handleSubmitProjectSummary = (e) => {
        e.preventDefault();

        if (!validatorProjectSummary.allValid()) {
            this.setState({ errors: validatorProjectSummary.getErrorMessages() });
            return false;
        }
        this.setState({ errors: {}, loadingProjectSummary: true });

        // // TODO: Remove this part when backend is fixed
        // let admin = [];
        // _.forEach(this.state.admins, function(v){
        //     admin.push(v.id)
        // });

        let params = {
            // admins: admin, // TODO: Remove this part when backend is fixed
            projectSummary: this.state.project_summary,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingProjectSummary: false,
            }, () => {
                this.__fetch();
                this.showMessage(true, 'Project Summary updated');
            })
        }).catch(err => {
            this.setState({ errors: err.errors, loadingProjectSummary: false });
            this.showMessage(false, 'Failed to update Project Summary');
        });
    };

    handleSubmitSummaryOfCost = (e) => {
        e.preventDefault();

        if (!validatorProjectSummary.allValid()) {
            this.setState({ errors: validatorProjectSummary.getErrorMessages() });
            return false;
        }
        this.setState({ errors: {}, loadingSummaryOfCost: true });

        // // TODO: Remove this part when backend is fixed
        // let admin = [];
        // _.forEach(this.state.admins, function(v){
        //     admin.push(v.id)
        // });

        let params = {
            // admins: admin, // TODO: Remove this part when backend is fixed
            place_of_repair: this.state.place_of_repair,
            summary_of_cost: this.state.summary_of_cost,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingSummaryOfCost: false,
            }, () => {
                this.__fetch();
                this.showMessage(true, 'Summary of Cost updated');
            })
        }).catch(err => {
            this.setState({ errors: err.errors, loadingSummaryOfCost: false });
            this.showMessage(false, 'Failed to update Summary of Cost');
        });
    };

    handleCommentClient = (e) => {
        e.preventDefault();

        if (!validatorCommentClient.allValid()) {
            this.setState({ errors: validatorCommentClient.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingComment: true });

        let param = {
            projects_id: this.props.match.params.id,
            user_id: this.state.user_id_current,
            type: 'comment',
            comment: this.state.client_comment,
        };

        Api.post('/client-comments', param, false).then(resp => {
            if (resp.data) {
                this.__fetchComment();
                this.setState({ loadingComment: false });
                this.showMessage(true, 'Comment posted');
            }
        }).catch(err => {
            this.setState({ errors: err.errors, loadingComment: false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleComment = (e) => {
        e.preventDefault();

        if (!validatorComment.allValid()) {
            this.setState({ errors: validatorComment.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingComment: true });

        let formData = new FormData();
        formData.append('projects_id', this.props.match.params.id);
        formData.append('user_id', this.state.user_id_current);
        formData.append('type', 'comment');
        formData.append('comment', this.state.user_comment);
        formData.append('image', this.state.image);

        Api.putFile('/comments',{
            method: 'POST',
            body: formData
        }).then(() => {
            this.setState({
                loadingComment: false,
                image: null,
                photo: null,
                user_comment: '',
            },
                () =>  this.__fetchComment()
            );

            this.showMessage(true, 'Comment posted');
        }).catch(err => {
            let message = 'Invalid format data';
            if (err?.errors?.comment?.[0]) message = err.errors.comment[0];

            this.setState(
                { errors: err.errors, loadingComment: false }, 
                () =>  this.__fetchComment()
            );
            this.showMessage(false, message);
        });
    };

    handleNewExpense = (e) => {
        this.setState({ errors: {} });
        let exp = this.state.expense;
        let temp = {
            date : null,
            description : '',
            amount : '',
            currency : '',
            remarks : '',
            survey_hour : '',
            type : 'out',
        };

        exp.push(temp);
        this.setState({ expense: exp });
    };

    handleNewAdvance = (e) => {
        this.setState({ errors: {} });

        let exp = this.state.expense;
        let temp = {
            date : null,
            description : '',
            amount : '',
            currency : '',
            remarks : '',
            survey_hour : '',
            type : 'in',
        };

        exp.push(temp);
        this.setState({ expense: exp });
    };

    handleChangeEx = (e, prop, id) => {
        let data = this.state.expense;

        _.forEach(data, function(v,k){

            if(k === id){
                if(prop === 'date') {
                    v[prop] = e;
                }else{
                    v[prop] = e.target.value;
                }
            }
        });

        this.setState({
            errors: {},
            expense: data,
        });
    };

    handleDelExp (e, id){
        this.setState({
                errors: {},
            }
        );

        let data = this.state.expense.filter((e, idx) =>idx !== id);

        this.setState({
            expense: data,
        });
    };

    handleSaveExpense = (e) => {
        e.preventDefault();

        this.setState({ errors: {}, loadingSave: true });

        let send = [];

        if(this.state.expense.length > 0) {
            _.forEach(this.state.expense, function(v){
                if(v.date !== null) {
                    v.date = moment(v.date).format('YYYY-MM-DD');
                }
                send.push(v)
            });
        }

        let params = {
            projects_id : this.props.match.params.id,
            expense : send,
        };
        Api.post('/expenses', params, false).then(resp => {

            this.setState({
                loadingSave: false,
                }
            );

            this.__fetchExpenses();
            this.showMessage(true, 'Project expenses update');

            let param = {
                total_survey_hour: this.state.survey_hours,
                survey_days : this.state.survey_days,
            };

            Api.patch('/projects/'+this.props.match.params.id, param, false).then(resp => {
                this.__fetch();
            }).catch(err => {
                this.setState({
                        errors: err.errors,
                    loadingSave: false
                    });
                });

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                    loadingSave: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    filterStatus = (name) => {
        let text = '';
        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = '';
        }

        return text;
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    handleOpen = () => {
        this.setState({ showDialog: true });
    };

    handleClose = () => {
        this.setState({ showDialog: false });
    };

    handleOpenRevert = () => {
        this.setState({ showDialogRevert: true });
    };

    handleCloseRevert = () => {
        this.setState({ showDialogRevert: false });
    };

    handleOpenDelete = () => {
        this.setState({ showDialogDelete: true });
    };

    handleCloseDelete = () => {
        this.setState({ showDialogDelete: false });
    };

    handleCancel = () => {
        this.setState({ loadingButton: true });
        let params = {
            cancel_status : 1,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({
                loadingButton: false,
                showDialog :false,
            }, () => {
                this.__fetch();
            });

            this.showMessage(true, 'Project Canceled');
        }).catch(err => {
            this.setState({ errors: err.errors, loadingButton: false, showDialog :false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleRevert = () => {
        this.setState({ loadingButton: true });
        let params = {
            cancel_status : 0,
        };

        Api.post('/projects/'+this.props.match.params.id+'/cancel-status', params, false).then(resp => {
            this.setState({
                loadingButton: false,
                showDialogRevert :false,
            }, () => {
                this.__fetch();
            });

            this.showMessage(true, 'Project Canceled');
        }).catch(err => {
            this.setState({ errors: err.errors, loadingButton: false, showDialogRevert :false });
            this.showMessage(false, 'Invalid format data');
        });
    };

    handleDelete = () => {
        this.setState({ loadingButton: true });

        Api.delete('/projects/'+this.props.match.params.id, '', false).then(resp => {
            this.setState({
                showDialogDelete: false,
                loadingButton :false,
            }, () => {
                this.__fetch();
            });

            this.showMessage(true, 'Project successfully deleted');
        }).catch(err => {
            this.setState({
                showDialogDelete: false,
                loadingButton :false,
            }, () => {
                this.__fetch();
            });
            this.showMessage(true, 'Project successfully deleted');
        });
    };

    handleDeleteComment = () => {
        this.setState({ loadingButton: true });

        Api.delete('/comments/'+this.state.idDelete, '', false).then(resp => {
            this.setState({
                    showDialogDeleteComment :false,
                    loadingButton :false,
                },
                () => this.__fetchComment()
            );

            this.showMessage(true, 'Comment successfully deleted');


        }).catch(err => {

            this.setState({
                    showDialogDeleteComment: false,
                    loadingButton :false,
                },
                () =>  this.__fetchComment()
            );

            this.showMessage(true, 'Comment successfully deleted');
        });
    };

    handleDeleteCommentClient = () => {

        this.setState({
                loadingButton: true,
            }
        );

        Api.delete('/client-comments/'+this.state.idDelete, '', false).then(resp => {

            this.setState({
                    showDialogDeleteComment :false,
                    loadingButton :false,
                },
                () => this.__fetchComment()
            );

            this.showMessage(true, 'Comment successfully deleted');


        }).catch(err => {

            this.setState({
                    showDialogDeleteComment: false,
                    loadingButton :false,
                },
                () =>  this.__fetchComment()
            );

            this.showMessage(true, 'Comment successfully deleted');
        });
    };

    handleAvatar = e => {
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            image: e.target.files[0]
        });
    };

    handleOpenDeleteComment = (row, type) => {
        this.setState({
            showDialogDeleteComment :true,
            idDelete: row.id,
            typeDelete: type
        })
    };

    handleCloseComment = () => {
        this.setState({
            showDialogDeleteComment :false,
            idDelete: ''
        })
    };

    renderReportOrApproval = (type_id) => {
        if (type_id === PROJECT_TYPE_IDS.report) { // Report
            return (
                <div className='card-detail'>
                    <h3>Repair</h3>
                    {
                        (this.state.repairs && this.state.repairs.length > 0) 
                            ? (this.state.repairs || []).map((item, key) => (
                                <>
                                    <p>{item?.item || '-'}</p>
                                    <p>Currency: <span>{item?.currency || '-'}</span></p>
                                    <p>Value / Amount: <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={item?.amount || 0} displayType={'text'} renderText={value => <span>{value}</span>}/></p>
                                    {/* <br /> */}
                                    {this.state.repairs.length !== (key + 1) && <hr />}
                                </>
                            )) : (
                                <p>No data</p>
                            )
                    }
                </div>
            );
        } else if (type_id === PROJECT_TYPE_IDS.approval) { // Approval
            return (
                <div className='card-detail'>
                    <h3>Estimate cost</h3>
                    {/* <p>Estimate Cost of Repair in Report: <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.estimation_cost} displayType={'text'} renderText={value => <span>{value}</span>}/></p> */}
                    <p>Estimate Cost of Repair in Report: <span>{this.state.estimation_cost}</span></p>
                    <p>Place of Repair / Shipyard Location: <span>{this.state.place_of_repair}</span></p>
                    <p>Total Account Submitted: <span>{this.state.total_account_submitted}</span></p>
                    <p>Total Account Approved: <span>{this.state.total_account_approved}</span></p>
                    <p>Total Account Approved for Cost: <span>{this.state.total_account_approved_for_cost}</span></p>
                    <p>Total Account Approved with Comment: <span>{this.state.total_account_approved_with_comment}</span></p>
                    <p>Total Account Not Approved: <span>{this.state.total_account_not_approved}</span></p>
                    <p>Residual Scrap Value: <span>{this.state.residual_scrap_value}</span></p>
                </div>
            );
        } else {
            return (
                <div className='card-detail'>
                    <h3>Estimate cost</h3>
                    <p>Currency: <span>{this.state.currency}</span></p>
                    {/* <p>Estimate Cost of Repair in Report: <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.estimation_cost} displayType={'text'} renderText={value => <span>{value}</span>}/></p> */}
                    <p>Estimate Cost of Repair in Report: <span>{this.state.estimation_cost}</span></p>
                </div>
            );
        }
    }

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>Detail Project {this.state.type_id === PROJECT_TYPE_IDS.approval ? 'Approval' : 'Report'}</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item'><Link to='/projects' >Project</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-md-4 mt-md-3 pl-lg-5'>
                    <div className={'card-abadi '+this.filterStatus(this.state.top_project_status)}>
                        <div className='icon-img'>
                            <img src={StatusProject} alt='abadi' className='img-fluid' />
                        </div>
                        <div className='detail'>
                            <p>Project Status</p>
                            <h3>{this.state.top_project_status}</h3>
                        </div>
                    </div>
                </div>
                {
                    this.filterPermission('finance-projects') &&
                    <div className='col-md-4 mt-md-3'>
                        <div className={'card-abadi '+this.filterStatus(this.state.top_finance_status)}>
                            <div className='icon-img'>
                                <img src={StatusFinance} alt='finance' className='img-fluid' />
                            </div>
                            <div className='detail'>
                                <p>Finance Status</p>
                                <h3>{this.state.top_finance_status}</h3>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.filterPermission('finance-projects') &&
                    <div className='col-md-4 mt-md-3'>
                        <div className='card-abadi green'>
                            <div className='icon-img'>
                                <img src={StatusOS} alt='status' className='img-fluid' />
                            </div>
                            <div className='detail'>
                                <p>OS Invoice Amount</p>
                                <h3>
                                    <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.top_os === null ? 0 : this.state.top_os} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </h3>
                            </div>
                        </div>
                    </div>
                }

                <div className='col-12'>
                    <div className='row'>
                        <div className='col-md mt-3 pl-lg-5 detail-wrap'>
                            <div className='btn-group mb-3 mb-lg-4'>
                                <button className='btn-tab active'>
                                    Project Detail
                                </button>
                                {
                                    this.filterPermission('finance-projects') &&
                                    <button className='btn-tab' onClick={() => this.handleGo('/projects/finance/'+this.props.match.params.id)}>
                                        Finance Detail
                                    </button>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    {
                                        this.state.loading ? (
                                            <div className='table-wrapper detail mb-3'>
                                                <h2 className='title-wrap'>Information</h2>
                                                <Skeleton variant='rect' className='mb-3' height={118} />
                                                <Skeleton variant='rect' className='mb-3' height={118} />
                                                <Skeleton variant='rect' className='mb-3' height={118} />
                                                <Skeleton variant='rect' className='mb-3' height={118} />
                                                <Skeleton variant='rect' className='mb-3' height={40} />
                                                <Skeleton variant='rect' className='mb-3' height={40} />
                                            </div> 
                                        ) : (
                                            <>
                                                <div className='table-wrapper detail mb-3'>
                                                    <h2 className='title-wrap'>Information</h2>
                                                    <div className='card-detail'>
                                                        <h3>Internal Data</h3>
                                                        <p>Case number: <span>{this.state.case_number}</span></p>
                                                        <p>Project number: <span>{this.state.project_number}</span></p>
                                                        <p>Ext project number: <span>{this.state.ext_project_number}</span></p>
                                                        <p>Case Type: <span>{this.state.case_type}</span></p>
                                                        <p>Project type: <span>{this.state.type}</span></p>
                                                        <p>Project detail type: <span>{this.state.detail_type}</span></p>
                                                        <p>Instruction date: <span>{this.state?.instruction_date ? moment(this.state.instruction_date).format('MMM, DD YYYY') : ''}</span></p>
                                                        <p>Approval Category: <span>{this.state?.approval_category?.name || ''}</span></p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>Vessel Particular</h3>
                                                        <p>Vessel name: <span>{this.state.vessel_name}</span></p>
                                                        <p>Vessel type: <span>{this.state.vesselTypeData?.name ? this.state.vesselTypeData.name : (this?.state?.vesselType || '')}</span></p>
                                                        <p>Vessel GRT: <span>{this.state.vessel_grt}</span></p>
                                                        <p>Vessel YOB: <span>{this.state.vessel_yob}</span></p>
                                                        <p>ENGINE Particular HP : <span>{this.state.engine_particular_hp}</span></p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>Client Detail</h3>
                                                        <p>Underwriter:
                                                            {
                                                                this.state.underwriters.length > 0 &&
                                                                this.state.underwriters.map(option => (
                                                                    <span className='d-block' key={option.id}>- {option.name}</span>
                                                                ))
                                                            }
                                                        </p>
                                                        <p>Broker: <span>{this.state.broker}</span></p>
                                                        <p>Shipwoner: <span>{this.state.shipowner}</span></p>
                                                        <p>Adjuster: <span>{this.state.adjuster}</span></p>
                                                        <p>Instructor: <span>{this.state.instructor}</span></p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>Accidents</h3>
                                                        <p>Casualty: <span>{this.state.casualty}</span></p>
                                                        <p>Casualty Type: <span>{this.state?.casualtyType?.name}</span></p>
                                                        <p>Place of accident: <span>{this.state.place_of_accident}</span></p>
                                                        <p>Date of accident: <span>{this.state?.date_of_accident ? moment(this.state.date_of_accident).format('MMM, DD YYYY') : ''}</span></p>
                                                        <p>Instruction by: <span>{this.state.instruction_by}</span></p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>Survey Data Internal</h3>
                                                        <p>Surveyor:
                                                            {
                                                                this.state.surveyor.length > 0 &&
                                                                this.state.surveyor.map(option => (
                                                                    <span className='d-block' key={option.id}>- {option.name}</span>
                                                                ))
                                                            }
                                                        </p>
                                                        <p>Report / Approval Handler:
                                                            {
                                                                this.state.admins.length > 0 &&
                                                                this.state.admins.map(option => (
                                                                    <span className='d-block' key={option.id}>- {option.name}</span>
                                                                ))
                                                            }
                                                        </p>
                                                        <p>Final Reviewer:
                                                            {
                                                                this.state.finalReviewer.length > 0 &&
                                                                this.state.finalReviewer.map(option => (
                                                                    <span className='d-block' key={option.id}>- {option.name}</span>
                                                                ))
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>PIC</h3>
                                                        <p>PIC for survey arrangement: <span>{this.state.pic_case}</span></p>
                                                    </div>
                                                    <div className='card-detail'>
                                                        <h3>Filling Admin</h3>
                                                        {this.state.hard_copy_invoice === 1 && <p>Hard copy invoice sudah di print</p>}
                                                        {this.state.hard_copy_report === 1 && <p>Hard copy report sudah di print</p>}
                                                        {this.state.soft_copy_data === 1 && <p>Softcopy data sudah tersedia</p>}
                                                        {this.state.files?.length > 0 && (
                                                            <div>
                                                                <p>File Project :</p>
                                                                {
                                                                    this.state.files.map((item) => (
                                                                        <p>
                                                                            <a 
                                                                                href={process.env.REACT_APP_API_STORAGE_PATH + item.file_name}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{ display: 'block', width: '100%' }}
                                                                            >
                                                                                <i className="fas fa-file"> </i> {item?.file_name}
                                                                            </a>
                                                                        </p>
                                                                    ))
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                                <div className='col-md-6'>
                                    <div className='card-edit mb-3'>
                                        <div className='row '>
                                            <div className='col-5 col-md-12 col-xl-5'>
                                                <img src={editImage} alt='edit' className='img-fluid' />
                                            </div>
                                            <div className='col-7 col-md-12 col-xl-7'>
                                                <p>UNTUK EDIT PROJEK SILAHKAN KLIK EDIT PROJECT</p>
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    className='full mb-3'
                                                    onClick={() => this.handleGo('/projects/edit/'+this.props.match.params.id)}
                                                    disabled={this.state.loadingButton}
                                                >
                                                    Edit Project{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                                                </Button>
                                            </div>

                                        </div>


                                    </div>

                                    {/* Estimate Cost of Repair */}
                                    {/* <div className='table-wrapper detail mb-3'>
                                        <h2 className='title-wrap'>
                                            {this.state.type_id === PROJECT_TYPE_IDS.approval ? 'Summary of Claim / Approval' : 'Estimate Cost of Repair in Report'}
                                        </h2>
                                        {
                                            this.state.loading 
                                                ? <Skeleton variant='rect' className='mb-3' height={70} />
                                                : this.renderReportOrApproval(this.state.type_id)
                                        }
                                    </div> */}

                                    {/* Summary Of Cost */}
                                    <div className='table-wrapper detail mb-3'>
                                        <h2 className='title-wrap'>Summary of Cost</h2>
                                        <div className="card-detail">
                                            <h3 className="pl-3">Summary of Cost</h3>
                                            <div className="form-group px-3 pt-3 mb-0">
                                                <TextField 
                                                    variant='outlined'
                                                    type='text'
                                                    id='place_of_repair'
                                                    name='place_of_repair'
                                                    label='Place of Repair / Shipyard Location'
                                                    onChange={(e) => this.handleChange(e, 'place_of_repair')}
                                                    value={this.state.place_of_repair}
                                                    fullWidth
                                                />
                                                {validatorSummaryOfCost.message('place_of_repair', this.state.place_of_repair, 'max:1500')}
                                                <div className='text-danger'>{this.state.errors?.place_of_repair}</div>
                                            </div>
                                            <div className="form-group px-3 pt-3">
                                                <TextField
                                                    label='Summary of Cost'
                                                    className="textarea-no-padding textarea-font-small"
                                                    variant="outlined"
                                                    color="primary"
                                                    type='text'
                                                    id='summary_of_cost'
                                                    name="summary_of_cost"
                                                    onChange={(e) => this.handleChange(e, 'summary_of_cost')}
                                                    placeholder="Write summary of project cost here"
                                                    value={this.state.summary_of_cost}
                                                    fullWidth
                                                    multiline
                                                    minRows={7}
                                                    maxRows={10}
                                                />
                                                {validatorSummaryOfCost.message('summary_of_cost', this.state.summary_of_cost, 'max:3000')}
                                                <div className='text-danger'>{this.state.errors.summary_of_cost}</div>
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="full"
                                                onClick={this.handleSubmitSummaryOfCost}
                                                disabled={this.state.loadingSummaryOfCost}
                                            >
                                                Update{ this.state.loadingSummaryOfCost && <i className="fa fa-spinner fa-spin"> </i> }
                                            </Button>
                                        </div>
                                    </div>

                                    {/* Status */}
                                    <div className='table-wrapper detail mb-3'>
                                        <h2 className='title-wrap'>Status {}</h2>
                                        {
                                            this.state.type === 'Report' &&
                                            <div className='card-detail'>
                                                <h3>Survey Status</h3>

                                                {
                                                    this.state.editSurvey ?
                                                        <div>
                                                            {/* <small>{moment(this.state.date_survey_status).format('YYYY/MM/DD')}</small>*/}
                                                            <form>
                                                                <TextField variant='outlined'
                                                                           select
                                                                           id='survey_status_id'
                                                                           name='survey_status_id'
                                                                           label='Survey status'
                                                                           onChange={(e) => this.handleChange(e, 'survey_status_id')}
                                                                           value={this.state.survey_status_id}
                                                                           fullWidth
                                                                >
                                                                    {this.state.survey_status.map(option => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                                {validatorSurvey.message('survey_status_id', this.state.survey_status_id, 'required')}
                                                                <div className='text-danger mb-2'>{this.state.errors?.survey_status_id}</div>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <DatePicker
                                                                        label="Date of Survey"
                                                                        value={this.state.date_survey_status}
                                                                        onChange={(date) => this.handleDate(date, 'date_survey_status')}
                                                                        format={'dddd, MMMM DD YYYY'}
                                                                        cancelLabel="BATAL"
                                                                        inputVariant="outlined"
                                                                        fullWidth
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                <div className='text-danger mb-2'>{this.state.errors?.date_status}</div>
                                                                <TextField variant='outlined'
                                                                           type='text'
                                                                           id='survey_comment'
                                                                           name='survey_comment'
                                                                           label='Place of Survey'
                                                                           onChange={(e) => this.handleChange(e, 'survey_comment')}
                                                                           value={this.state.survey_comment}
                                                                           fullWidth
                                                                />
                                                                <div className='text-danger mb-2'>{this.state.errors?.survey_comment}</div>
                                                                <TextField 
                                                                    variant='outlined'
                                                                    type='text'
                                                                    id='survey_remarks'
                                                                    name='survey_remarks'
                                                                    label='Remarks'
                                                                    onChange={(e) => this.handleChange(e, 'survey_remarks')}
                                                                    value={this.state.survey_remarks}
                                                                    fullWidth
                                                                />
                                                                <div className='text-danger'>{this.state.errors?.survey_remarks}</div>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Button
                                                                        variant='contained'
                                                                        color='primary'
                                                                        className='full out mt-2'
                                                                        onClick={this.handleSubmitStatusSurvey}
                                                                        disabled={this.state.loadingSurvey }
                                                                    >
                                                                        Save Status{ this.state.loadingSurvey && <i className='fa fa-spinner fa-spin'> </i> }
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="button-cancel full mt-2"
                                                                        onClick={this.handleCancelEditStatusSurvey}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        :
                                                        this.state.loading ?
                                                            <div>
                                                                <Skeleton variant='text' className='mb-3' />
                                                            </div>:
                                                            <div>
                                                                <p>Survey status : <strong>{this.state.survey_status_name}</strong></p>
                                                                <p>Date of Survey : <strong>{this.state.date_survey_status ? moment(this.state.date_survey_status).format('DD MMM YYYY') : ''}</strong></p>
                                                                <p>Place of Survey : <strong>{this.state.survey_comment}</strong></p>
                                                                <p>Remarks : <strong>{this.state.survey_remarks}</strong></p>
                                                                <Button
                                                                    variant='contained'
                                                                    color='primary'
                                                                    className='full mt-3'
                                                                    onClick={this.handleEditStatusSurvey}
                                                                    disabled={this.state.loadingSurvey}
                                                                >
                                                                    Change Status{ this.state.loadingSurvey && <i className='fa fa-spinner fa-spin'> </i> }
                                                                </Button>
                                                            </div>
                                                }

                                            </div>
                                        }
                                        {
                                            this.state.project_survey_status &&
                                            (this.state.type === 'Report' && this.state.project_survey_status.project_status === 'On Progress') &&
                                            (
                                                <div className='card-detail'>
                                                    <h3>{this.state.type} Status</h3>
                                                    {
                                                        this.state.editStatus ?
                                                            <div>
                                                                <form>
                                                                    {
                                                                        this.state.type === 'Report' ?
                                                                            <TextField variant='outlined'
                                                                                    select
                                                                                    id='project_status_id'
                                                                                    name='project_status_id'
                                                                                    label='Report status:'
                                                                                    onChange={(e) => this.handleChange(e, 'project_status_id')}
                                                                                    value={this.state.project_status_id}
                                                                                    fullWidth
                                                                            >
                                                                                {this.state.report_status.map(option => (
                                                                                    <MenuItem key={option.id} value={option.id}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            :
                                                                            this.state.type === 'Approval' &&
                                                                            <TextField variant='outlined'
                                                                                    select
                                                                                    id='project_status_id'
                                                                                    name='project_status_id'
                                                                                    label='Approval status'
                                                                                    onChange={(e) => this.handleChange(e, 'project_status_id')}
                                                                                    value={this.state.project_status_id}
                                                                                    fullWidth
                                                                            >
                                                                                {this.state.approval_status.map(option => (
                                                                                    <MenuItem key={option.id} value={option.id}>
                                                                                        {option.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                    }
                                                                    {validatorProject.message('project_status_id', this.state.project_status_id, 'required')}
                                                                    <div className='text-danger mb-2'>{this.state.errors?.project_status_id}</div>
                                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                        <DatePicker
                                                                            label="Date of Execute/Issued"
                                                                            value={this.state.date_status}
                                                                            onChange={(date) => this.handleDate(date, 'date_status')}
                                                                            format={'dddd, MMMM DD YYYY'}
                                                                            cancelLabel="BATAL"
                                                                            inputVariant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <div className='text-danger mb-2'>{this.state.errors?.date_status}</div>
                                                                    <TextField variant='outlined'
                                                                            type='text'
                                                                            id='comment'
                                                                            name='comment'
                                                                            label='Remarks'
                                                                            onChange={(e) => this.handleChange(e, 'comment')}
                                                                            value={this.state.comment}
                                                                            fullWidth
                                                                    />
                                                                    <div className='text-danger'>{this.state.errors?.comment}</div>
                                                                    <div>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <Button
                                                                                variant='contained'
                                                                                color='primary'
                                                                                className='full out mt-2'
                                                                                onClick={this.handleSubmitStatus}
                                                                                disabled={this.state.loadingProject}
                                                                            >
                                                                                Save Status{ this.state.loadingProject && <i className='fa fa-spinner fa-spin'> </i> }
                                                                            </Button>
                                                                            <Button
                                                                                variant="contained"
                                                                                className="button-cancel full mt-2"
                                                                                onClick={this.handleCancelEditStatus}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            :
                                                            this.state.loading ?
                                                                <div>
                                                                    <Skeleton variant='text' className='mb-3' />
                                                                </div> :
                                                                <div>
                                                                    <p>{this.state.type} status: <strong>{this.state.project_status_name}</strong></p>
                                                                    <p>Date Execute/Issued: <strong>{this.state.date_status ? moment(this.state.date_status).format('DD MMM YYYY') : null}</strong></p>
                                                                    <p>Remarks: <strong>{this.state.comment}</strong></p>
                                                                    <Button
                                                                        variant='contained'
                                                                        color='primary'
                                                                        className='full mt-3'
                                                                        onClick={this.handleEditStatus}
                                                                        disabled={this.state.loadingProject }
                                                                    >
                                                                        Change Status{ this.state.loadingProject && <i className='fa fa-spinner fa-spin'> </i> }
                                                                    </Button>
                                                                </div>
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.type === 'Approval' &&
                                            <div className='card-detail'>
                                                <h3>{this.state.type} Status</h3>
                                                {
                                                    this.state.editStatus ?
                                                        <div>
                                                            <form>
                                                                {
                                                                    this.state.type === 'Report' ?
                                                                        <TextField variant='outlined'
                                                                                   select
                                                                                   id='project_status_id'
                                                                                   name='project_status_id'
                                                                                   label='Report status:'
                                                                                   onChange={(e) => this.handleChange(e, 'project_status_id')}
                                                                                   value={this.state.project_status_id}
                                                                                   fullWidth
                                                                        >
                                                                            {this.state.report_status.map(option => (
                                                                                <MenuItem key={option.id} value={option.id}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                        :
                                                                        this.state.type === 'Approval' &&
                                                                        <TextField variant='outlined'
                                                                                   select
                                                                                   id='project_status_id'
                                                                                   name='project_status_id'
                                                                                   label='Approval status'
                                                                                   onChange={(e) => this.handleChange(e, 'project_status_id')}
                                                                                   value={this.state.project_status_id}
                                                                                   fullWidth
                                                                        >
                                                                            {this.state.approval_status.map(option => (
                                                                                <MenuItem key={option.id} value={option.id}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                }
                                                                {validatorProject.message('project_status_id', this.state.project_status_id, 'required')}
                                                                <div className='text-danger mb-2'>{this.state.errors?.project_status_id}</div>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <DatePicker
                                                                        label="Date of Execute/Issued"
                                                                        value={this.state.date_status}
                                                                        onChange={(date) => this.handleDate(date, 'date_status')}
                                                                        format={'dddd, MMMM DD YYYY'}
                                                                        cancelLabel="BATAL"
                                                                        inputVariant="outlined"
                                                                        fullWidth
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                <div className='text-danger mb-2'>{this.state.errors?.date_status}</div>
                                                                <TextField variant='outlined'
                                                                           type='text'
                                                                           id='comment'
                                                                           name='comment'
                                                                           label='Remarks'
                                                                           onChange={(e) => this.handleChange(e, 'comment')}
                                                                           value={this.state.comment}
                                                                           fullWidth
                                                                />
                                                                <div className='text-danger'>{this.state.errors?.comment}</div>
                                                                <div>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <Button
                                                                            variant='contained'
                                                                            color='primary'
                                                                            className='full out mt-2'
                                                                            onClick={this.handleSubmitStatus}
                                                                            disabled={this.state.loadingProject}
                                                                        >
                                                                            Save Status{ this.state.loadingProject && <i className='fa fa-spinner fa-spin'> </i> }
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            className="button-cancel full mt-2"
                                                                            onClick={this.handleCancelEditStatus}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        :
                                                        this.state.loading ?
                                                            <div>
                                                                <Skeleton variant='text' className='mb-3' />
                                                            </div> :
                                                            <div>
                                                                <p>{this.state.type} status: <strong>{this.state.project_status_name}</strong></p>
                                                                <p>Date Execute/Issued: <strong>{this.state.date_status ? moment(this.state.date_status).format('DD MMM YYYY') : null}</strong></p>
                                                                <p>Remarks: <strong>{this.state.comment}</strong></p>
                                                                <Button
                                                                    variant='contained'
                                                                    color='primary'
                                                                    className='full mt-3'
                                                                    onClick={this.handleEditStatus}
                                                                    disabled={this.state.loadingProject }
                                                                >
                                                                    Change Status{ this.state.loadingProject && <i className='fa fa-spinner fa-spin'> </i> }
                                                                </Button>
                                                            </div>

                                                }
                                            </div>
                                        }

                                        <div className='card-detail'>
                                            <h3>Case Status</h3>
                                            {this.state.editStatusCase ? (
                                                <div>
                                                    <form>
                                                        <TextField 
                                                            variant='outlined'
                                                            id='case_status_id'
                                                            name='case_status_id'
                                                            label='Case status: '
                                                            onChange={(e) => this.handleChange(e, 'case_status_id')}
                                                            value={this.state.case_status_id}
                                                            select
                                                            fullWidth
                                                        >
                                                            {this.state.case_status.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                                        </TextField>
                                                        {validatorProject.message('case_status_id', this.state.case_status_id, 'required')}
                                                        <div className='text-danger mb-2'>{this.state.errors?.case_status_id}</div>
                                                        <div>
                                                            <div className="d-flex justify-content-center align-items-center" style={{ gap: '4px' }}>
                                                                <Button
                                                                    variant='contained'
                                                                    color='primary'
                                                                    className='full out mt-2'
                                                                    onClick={this.handleSubmitStatusCase}
                                                                    disabled={this.state.loadingCase}
                                                                >
                                                                    Save Status{ this.state.loadingCase && <i className='fa fa-spinner fa-spin'> </i> }
                                                                </Button>
                                                                <Button
                                                                    variant='contained'
                                                                    color='secondary'
                                                                    className="reject full mt-2"
                                                                    style={{ fontSize: '13px', padding: '5px' }}
                                                                    onClick={this.handleClearCaseStatus}
                                                                >
                                                                    Clear Status{ this.state.loadingCase && <i className='fa fa-spinner fa-spin'> </i> }
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    className="button-cancel full mt-2 ml-0"
                                                                    onClick={this.handleCancelEditStatusCase}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            ) : (
                                                this.state.loading ? (<div><Skeleton variant='text' className='mb-3' /></div>) :
                                                (
                                                    <div>
                                                        <p>Case status : <strong>{this.state.case_status_name || '-'}</strong></p>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            className='full mt-3'
                                                            onClick={this.handleEditStatusCase}
                                                            disabled={this.state.loadingCase}
                                                        >
                                                            Change Status{ this.state.loadingCase && <i className='fa fa-spinner fa-spin'> </i> }
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {
                            this.filterPermission('list-comments') &&
                            <div className='col-md mt-3 comment-wrap'>
                                <h3>Comment</h3>

                                <div className='comment-container'>
                                    { this.state.loadingCommentList ?
                                        <div className='row mb-2'>
                                            <div className='col img-avatar'>
                                                <Skeleton variant='circle' width={60} height={60} />
                                            </div>
                                            <div className='col'>
                                                <Skeleton variant='text' />
                                                <Skeleton variant='text' />
                                            </div>
                                        </div> :
                                        this.state.comment_list.length === 0 ?
                                            <div className='col img-avatar mb-2'>
                                                <p>no comment</p>
                                            </div>
                                            :
                                            this.state.comment_list.map(row => (
                                                <div className='row mb-2 position-relative' key={row.id}>
                                                    <div className='col img-avatar'>
                                                        <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className='img-fluid' />
                                                    </div>
                                                    <div className='col'>
                                                        <h4>{row.user ? row.user.name : ''}</h4>
                                                        <p>{row.comment}</p>
                                                        {row.image &&
                                                        <img
                                                            src={process.env.REACT_APP_API_STORAGE_PATH + row.image}
                                                            alt='avatar' className='img-fluid'/>
                                                        }
                                                        <time>{moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</time>
                                                    </div>
                                                    <button className="btn-icon project-detail" onClick={() => this.handleOpenDeleteComment(row, 'project')}>
                                                        <i className="fas fa-trash-alt"> </i>
                                                    </button>
                                                </div>
                                            ))
                                    }
                                </div>

                                {this.state.photo &&
                                <img src={this.state.photo} alt="user" className="photo-image mr-2" />
                                }

                                <input
                                    type="file"
                                    id='image'
                                    name="image"
                                    className="image"
                                    onChange={this.handleAvatar}
                                />
                                {
                                    this.filterPermission('create-comments') &&
                                    <form>
                                        <input
                                            name='user_comment'
                                            onChange={(e) => this.handleChange(e, 'user_comment')}
                                            value={this.state.user_comment}
                                            placeholder='Enter your message'
                                        />
                                        <label htmlFor="image" className="label-file btn btn-upload">
                                            <i className="fas fa-camera-retro"> </i>
                                        </label>
                                        <button onClick={this.handleComment} >
                                            { this.state.loadingComment ? <i className='fa fa-spinner fa-spin'> </i>
                                                : <SendIcon />
                                            }
                                        </button>
                                    </form>
                                }

                                {validatorComment.message('user_comment', this.state.user_comment, 'required')}
                                <div className='text-danger mb-2'>{this.state.errors?.user_comment}</div>

                                <hr />
                                <h3>Comment Client</h3>

                                <div className='comment-container'>
                                    { this.state.loadingCommentListClient ?
                                        <div className='row mb-2'>
                                            <div className='col img-avatar'>
                                                <Skeleton variant='circle' width={60} height={60} />
                                            </div>
                                            <div className='col'>
                                                <Skeleton variant='text' />
                                                <Skeleton variant='text' />
                                            </div>
                                        </div> :
                                        this.state.comment_list_client.length === 0 ?
                                            <div className='col img-avatar mb-2'>
                                                <p>no comment</p>
                                            </div>
                                            :
                                            this.state.comment_list_client.map(row => (
                                                <div className='row mb-2 position-relative' key={row.id}>
                                                    <div className='col img-avatar'>
                                                        <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className='img-fluid' />
                                                    </div>
                                                    <div className='col'>
                                                        <h4>{row.user ? row.user.name : ''} {row.client ? row.client.name : ''}</h4>
                                                        <p>{row.comment}</p>
                                                        {/*{row.image &&*/}
                                                        {/*<img*/}
                                                        {/*    src={process.env.REACT_APP_API_STORAGE_PATH + row.image}*/}
                                                        {/*    alt='avatar' className='img-fluid'/>*/}
                                                        {/*}*/}
                                                        <time>{moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</time>
                                                    </div>
                                                    <button className="btn-icon project-detail" onClick={() => this.handleOpenDeleteComment(row, 'client')}>
                                                        <i className="fas fa-trash-alt"> </i>
                                                    </button>
                                                </div>
                                            ))
                                    }
                                </div>

                                {this.state.photo &&
                                <img src={this.state.photo} alt="client" className="photo-image mr-2" />
                                }

                                <input
                                    type="file"
                                    id='image'
                                    name="image"
                                    className="image"
                                    onChange={this.handleAvatar}
                                />
                                {
                                    this.filterPermission('create-comments') &&
                                    <form>
                                        <input
                                            name='client_comment'
                                            onChange={(e) => this.handleChange(e, 'client_comment')}
                                            value={this.state.client_comment}
                                            placeholder='Enter your message'
                                        />
                                        <button onClick={this.handleCommentClient} >
                                            { this.state.loadingComment ? <i className='fa fa-spinner fa-spin'> </i>
                                                : <SendIcon />
                                            }
                                        </button>
                                    </form>
                                }

                                {validatorCommentClient.message('client_comment', this.state.client_comment, 'required')}
                                <div className='text-danger mb-2'>{this.state.errors?.client_comment}</div>

                                <div className="card-cancel mt-4">
                                    {this.state.top_project_status === 'Cancel' ?
                                    <>
                                        <h3>REVERT CANCELED PROJECT</h3>
                                        <p>PROJECT STATUS MENJADI KEMBALI</p>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="reject full mb-4"
                                            onClick={() => this.handleOpenRevert()}
                                            disabled={this.state.loadingButton }
                                        >
                                            Revert Project{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </> :
                                        <>
                                            <h3>CANCEL PROJECT</h3>
                                            <p>PROJECT STATUS MENJADI CANCEL</p>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className="reject full mb-4"
                                                onClick={() => this.handleOpen()}
                                                disabled={this.state.loadingButton }
                                            >
                                                Cancel Project{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                            </Button>
                                        </>
                                    }

                                    <h3>DELETE PROJECT</h3>
                                    <p>PROJECT STATUS MENGHILANG DARI SISTEM</p>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="reject full"
                                        onClick={() => this.handleOpenDelete()}
                                        disabled={this.state.loadingButton }
                                    >
                                        Delete Project{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                    </Button>
                                    <img src={cancel} alt='project' className="img-fluid" />
                                </div>

                                <div className='table-wrapper detail mt-4 mb-4'>
                                    <div className="card-detail">
                                        <h3 className="pl-3">Project Summary</h3>
                                        <div className="form-group px-3">
                                            <label>Summary of this Project</label>
                                            <TextField
                                                className="textarea-no-padding textarea-font-small"
                                                variant="outlined"
                                                color="primary"
                                                type='text'
                                                id='project_summary'
                                                name="project_summary"
                                                onChange={(e) => this.handleChange(e, 'project_summary')}
                                                value={this.state.project_summary}
                                                fullWidth
                                                multiline
                                                minRows={7}
                                                maxRows={10}
                                            />
                                            {validatorProjectSummary.message('project_summary', this.state.project_summary, 'max:3000')}
                                            <div className='text-danger'>{this.state.errors.project_summary}</div>
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="full"
                                            onClick={this.handleSubmitProjectSummary}
                                            disabled={this.state.loadingProjectSummary}
                                        >
                                            Update{ this.state.loadingProjectSummary && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>

                {
                    this.filterPermission('view-expenses') &&
                    <div className='col-12 pl-lg-5'>
                        <div className='table-wrapper detail table-exp'>
                            <div className='row align-items-center mb-3 mx-0'>
                                <div className='col-md-6'>
                                    <h2 className='title-wrap mb-0'> Project Expenses</h2>
                                </div>
                                {
                                    this.filterPermission('create-expenses') &&
                                    <div className='col-md-6 text-right'>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            className='mr-2'
                                            onClick={this.handleNewExpense}
                                        >
                                            New Expense
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            className='mr-2'
                                            onClick={this.handleNewAdvance}
                                        >
                                            Cash Advance
                                        </Button>
                                    </div>
                                }

                            </div>
                            <div className='row head-form'>
                                <div className='col-12 col-xl-1'>
                                    <strong>Action</strong>
                                </div>
                                <div className='col-12 col-xl-2 d-none d-xl-block' >
                                    <strong>Date</strong>
                                </div>
                                <div className='col-12 col-xl-2 d-none d-xl-block'>
                                    <strong>Description</strong>
                                </div>
                                <div className='col-12 col-xl-2 d-none d-xl-block'>
                                    <strong>Amount</strong>
                                </div>
                                <div className='col-12 col-xl d-none d-xl-block'>
                                    <strong>Currency</strong>
                                </div>
                                <div className='col-12 col-xl d-none d-xl-block'>
                                    <strong>Remarks</strong>
                                </div>

                            </div>
                            {
                                this.state.expense.length > 0 &&
                                this.state.expense.map(function (item, idx) {
                                    return <div className='row align-items-center' key={idx}>
                                        <div className='col-6 col-md-4 col-xl-1 text-center'>
                                            <button className={'btn-icon' + (item.type === 'red' ? ' green' : '')} onClick={(e) => this.handleDelExp(e, idx)}>
                                                <i className='fas fa-trash-alt'> </i>
                                            </button>
                                        </div>
                                        <div className='col-6 col-md-4 col-xl-2'>
                                            <div className='form-group'>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        label='Date'
                                                        value={item.date}
                                                        onChange={(date) => this.handleChangeEx(date, 'date', idx)}
                                                        format={'ddd, MMMM DD YYYY'}
                                                        cancelLabel='BATAL'
                                                        inputVariant='outlined'
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {validatorEx.message('date-'+idx, item.date, 'required')}
                                                <div className='text-danger'>{this.state.errors?.['date-'+idx]}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-4 col-xl-2'>
                                            <div className='form-group'>
                                                <TextField variant='outlined'
                                                           type='text'
                                                           id={'description-'+idx}
                                                           name={'description-'+idx}
                                                           onChange={(e) => this.handleChangeEx(e, 'description', idx)}
                                                           label='Description'
                                                           value={item.description ? item.description : ''}
                                                           fullWidth
                                                />
                                                {validatorEx.message('description-'+idx, item.description, 'required')}
                                                <div className='text-danger'>{this.state.errors?.['description-'+idx]}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-4 col-xl-2'>
                                            <div className='form-group'>
                                                <TextField variant='outlined'
                                                           type='text'
                                                           id={'amount-'+idx}
                                                           name={'amount-'+idx}
                                                           label='Amount'
                                                           value={item.amount ? item.amount : 0}
                                                           onChange={(e) => this.handleChangeEx(e, 'amount', idx)}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validatorEx.message('amount-'+idx, item.amount, 'required')}
                                                <div className='text-danger'>{this.state.errors?.['amount-'+idx]}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-4 col-xl'>
                                            <div className='form-group'>
                                                <TextField variant='outlined'
                                                           select
                                                           id={'currency-'+idx}
                                                           name={'currency-'+idx}
                                                           label='Currency'
                                                           onChange={(e) => this.handleChangeEx(e, 'currency', idx)}
                                                           value={item.currency}
                                                           fullWidth
                                                >
                                                    <MenuItem value='IDR'>
                                                        IDR
                                                    </MenuItem>
                                                    <MenuItem value='USD'>
                                                        USD
                                                    </MenuItem>
                                                    <MenuItem value='SGD'>
                                                        SGD
                                                    </MenuItem>
                                                </TextField>
                                                {validatorEx.message('currency-'+idx, item.currency, 'required')}
                                                <div className='text-danger'>{this.state.errors?.['currency-'+idx]}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-4 col-xl'>
                                            <div className='form-group'>
                                                <TextField variant='outlined'
                                                           type='text'
                                                           id={'remarks-'+idx}
                                                           name={'remarks-'+idx}
                                                           label='Remarks'
                                                           onChange={(e) => this.handleChangeEx(e, 'remarks', idx)}
                                                           value={item.remarks ? item.remarks : ''}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors?.['remarks-'+idx]}</div>
                                            </div>
                                        </div>

                                    </div>
                                }.bind(this))
                            }

                            <div className='row mx-0 mt-3'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Cash Advance</label>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='total_in'
                                                   name='total_in'
                                                   label='Total Cash'
                                                   value={this.state.total_in}
                                                   InputProps={{
                                                       inputComponent: this.NumberFormatCustom,
                                                   }}
                                                   disabled
                                                   fullWidth
                                        />
                                        {validatorEx.message('total_in', this.state.total_in, 'required')}
                                        <div className='text-danger'>{this.state.errors?.total_in}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Expenses</label>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='total_out'
                                                   name='total_out'
                                                   label='Total Expenses'
                                                   value={this.state.total_out}
                                                   InputProps={{
                                                       inputComponent: this.NumberFormatCustom,
                                                   }}
                                                   disabled
                                                   fullWidth
                                        />
                                        {validatorEx.message('total_out', this.state.total_out, 'required')}
                                        <div className='text-danger'>{this.state.errors?.total_out}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Saldo</label>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='saldo'
                                                   name='saldo'
                                                   label='Saldo'
                                                   value={this.state.saldo}
                                                   InputProps={{
                                                       inputComponent: this.NumberFormatCustom,
                                                   }}
                                                   disabled
                                                   fullWidth
                                        />
                                        {validatorEx.message('saldo', this.state.saldo, 'required')}
                                        <div className='text-danger'>{this.state.errors?.saldo}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Survey Hour</label>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='survey_hours'
                                                   name='survey_hours'
                                                   label='Total Survey Hour'
                                                   onChange={(e) => this.handleChange(e, 'survey_hours')}
                                                   value={this.state.survey_hours}
                                                   fullWidth
                                        />
                                        {validatorEx.message('survey_hours', this.state.survey_hours, 'required')}
                                        <div className='text-danger'>{this.state.errors?.survey_hours}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Survey Days</label>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='survey_days'
                                                   name='survey_days'
                                                   label='Total Survey Days'
                                                   onChange={(e) => this.handleChange(e, 'survey_days')}
                                                   value={this.state.survey_days}
                                                   fullWidth
                                        />
                                        {validatorEx.message('survey_days', this.state.survey_days, 'required')}
                                        <div className='text-danger'>{this.state.errors?.survey_days}</div>
                                    </div>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className='mt-4'
                                        onClick={this.handleSaveExpense}
                                        disabled={this.state.loadingSave}
                                    >
                                        Save{ this.state.loadingSave && <i className='fa fa-spinner fa-spin'> </i> }
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>CANCEL PROJECT:</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna cancel this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            No
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleCancel}
                            disabled={this.state.loadingButton}
                        >
                            Yes{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogRevert}
                    onClose={this.handleCloseRevert}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>REVERT PROJECT:</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna revert this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleCloseRevert}
                        >
                            No
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleRevert}
                            disabled={this.state.loadingButton}
                        >
                            Yes{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogDelete}
                    onClose={this.handleCloseDelete}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: </DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna Delete this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleCloseDelete}
                        >
                            No
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton}
                        >
                            Yes{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogDeleteComment}
                    onClose={this.handleCloseComment}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE COMMENT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this comment?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleCloseComment}
                        >
                            Cancel
                        </Button>
                        {this.state.typeDelete === 'project' &&
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDeleteComment}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                        }
                        {this.state.typeDelete === 'client' &&
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDeleteCommentClient}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                        }

                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectsDetail));
