import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

import Api from '../../../../../libraries/api';
import { CHART_COLOR_PALETTE as CHART_COLORS } from './colors';


export default function InstructionComparison3YearsTab(props) {
    const { value, index, ...other } = props;
    const [year, setYear] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2001 }, (_, i) => 2002 + i);

    const fetchData = () => {
        setLoading(true);

        Api.get(`/charts/report/new_instructions_per_case_type_yearly?year=${year}`)
            .then((resp) => {
                if (resp.data) {
                    const data = resp.data;
                    const months = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
                    ];

                    const datasets = Object.keys(data).map((year, idx) => {
                        // const chartColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`;
                        const chartColor = CHART_COLORS[idx];
                        
                        return {
                            label: year,
                            data: months.map((month) => data[year][month] || 0),
                            backgroundColor: chartColor,
                            borderColor: chartColor,
                            borderWidth: 1,
                            maxBarThickness: 25,
                        };
                    });

                    setChartData({ labels: months, datasets });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeYear = (e) => setYear(e.target.value);

    useEffect(() => {
        fetchData();
    }, [year]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    offset: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    offset: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw}`,
                },
            },
        },
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="table-wrapper">
                    <div className='row align-items-center justify-content-end mb-md-3'>
                        <div className="col-md-6 col-lg-4 mr-auto">
                            <div className="form-group">
                                <label>Year</label>
                                <TextField
                                    variant="outlined"
                                    select
                                    id="year"
                                    name="year"
                                    onChange={handleChangeYear}
                                    value={year}
                                    fullWidth
                                >
                                    {years.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-end mb-md-3">
                        <div className="col-12">
                            <div style={{ minHeight: '500px', height: '60vh' }}>
                                {loading ? (
                                    <p>Loading chart data...</p>
                                ) : chartData ? (
                                    <Bar data={chartData} options={options} />
                                ) : (
                                    <p>No data available</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
