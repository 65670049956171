/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

import Api from '../../../../../libraries/api';
import { CHART_COLOR_PALETTE as CHART_COLORS } from './colors';


export default function InstructionPerCaseTypeTab(props) {
    const { value, index, ...other } = props;
    const [year, setYear] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2001 }, (_, i) => 2002 + i);

    const fetchData = () => {
        setLoading(true);

        Api.get(`/charts/report/new_instructions_per_case_type?year=${year}`)
            .then((resp) => {
                if (resp.data) {
                    const data = resp.data;
                    const labels = Object.keys(data);
                    const values = Object.values(data);

                    setChartData({
                        labels,
                        datasets: [
                            {
                                label: 'New Instructions',
                                data: values,
                                backgroundColor: CHART_COLORS,
                                borderColor: '#fff',
                                borderWidth: 2,
                            },
                        ],
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeYear = (e) => setYear(e.target.value);

    useEffect(() => {
        fetchData();
    }, [year]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => `${context.label}: ${context.raw}`,
                },
            },
            legend: {
                position: 'bottom',
            },
        },
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="table-wrapper">
                    <div className="row align-items-center justify-content-end mb-md-3">
                        <div className="col-md-6 col-lg-4 mr-auto">
                            <div className="form-group">
                                <label>Year</label>
                                <TextField
                                    variant="outlined"
                                    select
                                    id="year"
                                    name="year"
                                    onChange={handleChangeYear}
                                    value={year}
                                    fullWidth
                                >
                                    {years.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-end mb-md-3">
                        <div className="col-12">
                            <div style={{ minHeight: '500px', height: '60vh' }}>
                                {loading ? (
                                    <p>Loading chart data...</p>
                                ) : chartData ? (
                                    <Doughnut data={chartData} options={options} />
                                ) : (
                                    <p>No data available</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
