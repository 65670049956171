import React,{Component} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import Api from '../../../libraries/api';
import TuneIcon from '@material-ui/icons/Tune';
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from 'react-toastify';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../presentational/table-pagination-actions';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {history} from '../../../shared/configure-store';
import NumberFormat from 'react-number-format';
import CommonHelper from '../../../libraries/common-helper';
import AuthHelper from '../../../libraries/auth-helper';

require('dotenv').config();


const styleWidth = {
    minWidth: 'none',
};

const styleWidth1 = {
    minWidth: 'none',
};

const styleMax = {
    width: 'auto',
};

class Invoices extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},
            permissions: [],

            orderBy: '',
            orderByStatus: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            case_number: '',
            project_number: '',
            invoice_number: '',
            status: '',
            financeStatus: '',
            client_id: '',
            client_type: '',
            remarks: '',
            vessel_name: '',
            currency: '',
            start_date_issued: null,
            end_date_issued: null,

            clients: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Finance';
        let permissions = AuthHelper.getProfile()?.permissions;

        this.setState({ permissions });

        this.__fetchData(false);
        Api.get('/clients?limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });
                this.setState({ clients: data });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            //project_number : this.state.project_number,
            invoice_number : this.state.invoice_number,
            client_type : this.state.client_type,
            client_id: this.state.client_id ? this.state.client_id.value : '',
            status: this.state.status,
            finance_status: this.state.financeStatus,
            remarks: this.state.remarks ? this.state.remarks : '',
            vessel_name: this.state.vessel_name,
            currency: this.state.currency,
            start_date_issued: this.state.start_date_issued ? moment(this.state.start_date_issued).format('YYYY-MM-DD') : '',
            end_date_issued: this.state.end_date_issued ? moment(this.state.end_date_issued).format('YYYY-MM-DD') : '',
        };

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        if(this.state.orderByStatus) {
            sort = this.state.orderBy ? '&order=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        }
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let route = '';

        if(this.state.showDialogFilter) {
            route = '/invoices?'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        }else if(search) {
            route = '/invoices?limit='+this.state.perPage+sort+search;
        }else {
            route = '/invoices?'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        rows: resp.data,
                        perPage: resp.meta.pagination.per_page,
                        currentPage: resp.meta.pagination.current_page,
                        currentPageTable: resp.meta.pagination.current_page - 1,
                        total: resp.meta.pagination.total,
                        loading: false,
                        loadingButton: false,
                        showDialogFilter: false,
                        isEdit: resp.meta.custom.isEdit,
                        isDelete: resp.meta.custom.isDelete,
                        isCreate: resp.meta.custom.isCreate,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;
        let sortStatus = '';

        if(val === 'case_number' || val === 'client_name'){
            sortStatus = 'order';
        }

        if(sortedBy && sortedBy === 'asc' ){
            sortedBy = 'desc';
        }else{
            sortedBy = 'asc';
        }

        this.setState({
            orderByStatus: sortStatus,
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){
        this.setState({ [prop]: date });
    };

    handleChangeClient (e, prop){

        this.setState({

            [prop]: e

        });

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenFilter = () => {
        this.setState({
            showDialogFilter :true,
        })
    };

    handleCloseFilter = () => {
        this.setState({
            showDialogFilter :false,
        })
    };

    handleSubmitFilter = () => {

        this.__fetchData(false);

    };

    handleResetFilter = () => {

        this.setState({
            case_number : '',
            invoice_number : '',
            project_number : '',
            ext_project_number : '',
            vessel_name : '',
            casualty: '',
            client_id: '',
            client_type: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            status: '',
            finance_status: '',
            currency: '',
            surveyorId: '',
            start_date_issued: null,
            end_date_issued: null,
        },() => {
            this.__fetchData(false);
        });

    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/invoices/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialog: false,
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                    showDialog: false
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterName = (id) => {
        let all = this.state.clients.filter((item) => item.id === id);

        if(all.length)
            return all[0].name;
    };
    filterStatus = (name) => {
        let text = '';
        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'pending':
                text = 'yellow';
                break;
            case 'paid':
                text = 'green';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    handleExport = () => {

        this._isMounted = true;

        this.setState({
            loadingButton: true,
        })

        let page = this.state.currentPage;

        let params = {
            case_number : this.state.case_number,
            //project_number : this.state.project_number,
            invoice_number : this.state.invoice_number,
            client_type : this.state.client_type,
            client_id: this.state.client_id ? this.state.client_id.value : '',
            status: this.state.status,
            remarks: this.state.remarks ? this.state.remarks : '',
            vessel_name: this.state.vessel_name,
            currency: this.state.currency,
            start_date_issued: this.state.start_date_issued ? moment(this.state.start_date_issued).format('YYYY-MM-DD') : '',
            end_date_issued: this.state.end_date_issued ? moment(this.state.end_date_issued).format('YYYY-MM-DD') : '',
        };

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        if(this.state.orderByStatus) {
            sort = this.state.orderBy ? '&order=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        }
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let filter = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        // let route = '';
        // if(params) {
        //     route = '/invoices?export=finance&'+filter+'&limit='+this.state.perPage+'&page='+page+sort+search;
        // }else if(search) {
        //     route = '/invoices?export=finance&limit='+this.state.perPage+sort+search;
        // }else {
        //     route = '/invoices?export=finance&limit='+this.state.perPage+'&page='+page+sort+search;
        // }

        const route = '/invoices?export=invoices&'+filter+'&limit=0'+sort+search;

        Api.get(route).then(resp => {
            if (this._isMounted) {
                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    });
                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loadingButton: false });
        });
    };

    handleExportAll = () => {

        this._isMounted = true;

        this.setState({
            loadingButton: true,
        })

        Api.get('/invoices?export=invoices').then(resp => {
            if (this._isMounted) {

                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingButton: false,
            })
        });

    };

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>Invoices</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Invoices</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-12 mt-3 px-lg-5'>
                    <div className='table-wrapper'>
                        <div className='row align-items-center mb-md-3'>
                            <div className='col-md-8'>
                                <TextField
                                    id='input-with-icon-textfield'
                                    variant='outlined'
                                    className='search-field'
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder='Search here'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='start'>
                                                <IconButton
                                                    aria-label='Search click'
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className='fas fa-search'> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='ml-3 round'
                                    onClick = {() => this.handleOpenFilter()}
                                >
                                    <TuneIcon />
                                    Filters
                                </Button>
                            </div>
                            <div className='col-md-4 text-md-right'>
                                {
                                    CommonHelper.checkPermission(this.state.permissions, 'export-invoices') && (
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className='mb-3 mb-md-0 mr-3'
                                                onClick={() => this.handleExport()}
                                            >
                                                Export { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className='mb-3 mb-md-0'
                                                onClick={() => this.handleExportAll()}
                                            >
                                                Export All{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                            </Button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <TableContainer>
                            <Table className='table-list' size='small' >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='clickable' onClick={() => this.handleSort('case_number')} >Case No
                                            {this.state.orderBy === 'case_number' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('invoice_number')} >Inv. No
                                            {this.state.orderBy === 'invoice_number' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell className='clickable' onClick={() => this.handleSort('date_issued')} >Date Issued
                                            {this.state.orderBy === 'date_issued' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleMax} className='clickable' onClick={() => this.handleSort('client_name')} >Client
                                            {this.state.orderBy === 'client_name' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('share_percentage')} >Shared%
                                            {this.state.orderBy === 'share_percentage' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('currency')} >Currency
                                            {this.state.orderBy === 'currency' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth1} className='clickable' onClick={() => this.handleSort('total_amount')} >Total
                                            {this.state.orderBy === 'total_amount' ?
                                                <span className='icon-sort'>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-up active' : 'fas fa-long-arrow-alt-up'}> </i>
                                                    <i className={this.state.sortedBy === 'asc' ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-down active'}> </i>
                                                </span> :
                                                <span className='icon-sort'>
                                                    <i className='fas fa-long-arrow-alt-up'> </i>
                                                    <i className='fas fa-long-arrow-alt-down'> </i>
                                                </span>}
                                        </TableCell>
                                        <TableCell style={styleWidth} className='clickable' >Payment Status</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={9} align='center' className='py-5'>
                                                <CircularProgress color='primary'  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No invoices</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <Link to={row.project === null ? '#' : '/projects/finance/'+row.project.id}  target='_blank'>
                                                            {row.project === null ? '' : row.project.case_number}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{row.invoiceNumber === null ? '' : row.invoiceNumber}</TableCell>
                                                    <TableCell>{row.dateIssued === null ? '' : moment(row.dateIssued).format('DD MMM YYYY')}</TableCell>
                                                    <TableCell>{row.client ? row.client.name : ''}</TableCell>
                                                    <TableCell>{row.sharePercentage === null ? '' : row.sharePercentage}</TableCell>
                                                    <TableCell>{row.currency === null ? '' : row.currency}</TableCell>
                                                    <TableCell><NumberFormat thousandSeparator={true} value={row.totalAmount} displayType={'text'} renderText={value => value}/></TableCell>
                                                    <TableCell><span className={'label-status label-'+this.filterStatus(row.statusInvoice)}>{row.statusInvoice}</span></TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component='div'
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this project?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='md'
                    fullWidth={true}
                    open={this.state.showDialogFilter}
                    onClose={this.handleCloseFilter}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>Filter Finance Invoice</DialogTitle>
                    <DialogContent>
                        <form name='filter' id='filterProject' className='row' noValidate>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Case No</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='case_number'
                                               name='case_number'
                                               label='Case No'
                                               onChange={(e) => this.handleChange(e, 'case_number')}
                                               value={this.state.case_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.case_number}</div>
                                </div>
                            </div>
                            {/*<div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Project No</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='project_number'
                                               name='project_number'
                                               label='Project No'
                                               onChange={(e) => this.handleChange(e, 'project_number')}
                                               value={this.state.project_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.project_number}</div>
                                </div>
                            </div>*/}
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Invoice Number</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='invoice_number'
                                               name='invoice_number'
                                               label='Invoice Number'
                                               onChange={(e) => this.handleChange(e, 'invoice_number')}
                                               value={this.state.invoice_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.invoice_number}</div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client</label>
                                    <Select options = {this.state.clients}
                                            onChange={(e) => this.handleChangeClient(e,'client_id')}
                                            value={this.state.client_id}
                                    />
                                    <div className='text-danger'>{this.state.errors.client_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="client_type"
                                               name="client_type"
                                               label="Type"
                                               onChange={(e) => this.handleChange(e, 'client_type')}
                                               value={this.state.client_type}
                                               fullWidth
                                    >
                                        <MenuItem value="Underwriter">
                                            Underwriter
                                        </MenuItem>
                                        <MenuItem value="Broker">
                                            Broker
                                        </MenuItem>
                                        <MenuItem value="Shipowner">
                                            Shipowner
                                        </MenuItem>
                                        <MenuItem value="Adjuster">
                                            Adjuster
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.client_type}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Invoice Payment Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='status'
                                               name='status'
                                               label='Payment Status'
                                               onChange={(e) => this.handleChange(e, 'status')}
                                               value={this.state.status}
                                               fullWidth
                                    >
                                        <MenuItem key='1' value='pending'>Pending</MenuItem>
                                        <MenuItem key='2' value='paid'>Paid</MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.status}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Finance Project Payment Status</label>
                                    <TextField variant='outlined'
                                               select
                                               id='financeStatus'
                                               name='financeStatus'
                                               label='Finance Status'
                                               onChange={(e) => this.handleChange(e, 'financeStatus')}
                                               value={this.state.financeStatus}
                                               fullWidth
                                    >
                                        <MenuItem key='1' value='Pending Payment'>Pending Payment</MenuItem>
                                        <MenuItem key='2' value='Partial Payment'>Partial Payment</MenuItem>
                                        <MenuItem key='3' value='Complete Payment'>Complete Payment</MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.financeStatus}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Currency</label>
                                    <TextField variant="outlined"
                                               select
                                               id="currency"
                                               name="currency"
                                               label="Currency"
                                               onChange={(e) => this.handleChange(e, 'currency')}
                                               value={this.state.currency}
                                               fullWidth
                                    >
                                        <MenuItem value="IDR">
                                            IDR
                                        </MenuItem>
                                        <MenuItem value="USD">
                                            USD
                                        </MenuItem>
                                        <MenuItem value="SGD">
                                            SGD
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.currency}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Vessel Name</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='vessel_name'
                                               name='vessel_name'
                                               label='Vessel Name'
                                               onChange={(e) => this.handleChange(e, 'vessel_name')}
                                               value={this.state.vessel_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_name}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Date Issued</label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    label='From'
                                                    value={this.state.start_date_issued}
                                                    onChange={(date) => this.handleDate(date, 'start_date_issued')}
                                                    format={'dd MMM yyyy'}
                                                    cancelLabel='BATAL'
                                                    inputVariant='outlined'
                                                    maxDate={this.state.end_date_issued || moment()}
                                                    autoOk
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className='text-danger'>{this.state.errors.start_date_issued}</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    label='To'
                                                    value={this.state.end_date_issued}
                                                    onChange={(date) => this.handleDate(date, 'end_date_issued')}
                                                    format={'dd MMM yyyy'}
                                                    cancelLabel='BATAL'
                                                    inputVariant='outlined'
                                                    minDate={this.state.start_date_issued}
                                                    maxDate={moment()}
                                                    autoOk
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className='text-danger'>{this.state.errors.end_date_issued}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Remarks</label>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='remarks'
                                               name='remarks'
                                               label='Remarks'
                                               onChange={(e) => this.handleChange(e, 'remarks')}
                                               value={this.state.remarks}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.remarks}</div>
                                </div>
                            </div>
                        </form>

                    </DialogContent>
                    <DialogActions className='justify-content-center pb-3'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleResetFilter}
                        >
                            Reset
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleSubmitFilter}
                            disabled={this.state.loadingButton && true }
                        >
                            Submit{ this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default Invoices;
