import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Chart from 'chart.js';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import { DateRangePicker } from "@shinabr2/react-material-daterange-picker";
//import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import Api from "../../../../libraries/api";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TuneIcon from "@material-ui/icons/Tune";
import _ from "lodash";

class OperationPerformanceCharts extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            openDate: false,
            loadingButton: false,
            startDate: null,
            endDate: null,
            years: [],
            year: '',
            type: '',
            dateRange: {},
            data: {
                labels: [],
                approval: {
                    data: [],
                },
                report: {
                    data: [],
                },
            },
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Chart';

        let rows = [];
        let year = moment().year();
        for (let i=2002; i <= year; i++) {
            rows.push(i);
        }

        this.setState({
            years: rows,
            year: year,
            type: 2,
            loading: true,
        }, () => {
            this.__fetch();
        });



    }

    buildChart(){
        let data = {
            labels: this.state.data.labels,
            datasets: [{
                label: 'Report',
                data: this.state.data.report.data,
                backgroundColor: '#1ebead',
                borderColor: '#1ebead',
                borderWidth: 1
            },
                {
                    label: 'Approval',
                    data: this.state.data.approval.data,
                    backgroundColor: '#5a7dfd',
                    borderColor: '#5a7dfd',
                    borderWidth: 1
                }]
        };

        let options = {
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        beginAtZero:true
                    }
                }]
            },
            // Container for pan options
            pan: {
                // Boolean to enable panning
                enabled: true,

                // Panning directions. Remove the appropriate direction to disable
                // Eg. 'y' would only allow panning in the y direction
                mode: 'x',

                speed: 1
            },

            // Con tainer for zoom options
            zoom: {
                // Boolean to enable zooming
                enabled: true,
                // Zooming directions. Remove the appropriate direction to disable
                // Eg. 'y' would only allow zooming in the y direction
                mode: 'x',
            }
        };

        if(window.myHorizBarChart != undefined){
            window.myHorizBarChart.destroy();
        }

        let ctxHBar = document.getElementById("horizontal_bar_chart");

        let horizontal_bar_chart = new Chart(ctxHBar, {
            type: 'bar',
            data: data,
            options: options
        });

        horizontal_bar_chart.ctx.canvas.addEventListener('wheel', ctxHBar._wheelHandler);


        this.setState({
            data: data, options: options
        });

    };

    __fetch = () => {
        let params = '/reports/statement?year='+this.state.year;

        Api.get(params).then(resp => {
            if (resp.data) {
                let data = resp.data;
                let values = [
                    resp.data.Jan,
                    resp.data.Feb,
                    resp.data.Mar,
                    resp.data.Apr,
                    resp.data.Mei,
                    resp.data.Jun,
                    resp.data.Jul,
                    resp.data.Agu,
                    resp.data.Sep,
                    resp.data.Okt,
                    resp.data.Nov,
                    resp.data.Des,
                ];
                let approvalIssued = [];
                let reportIssued = [];
                let approvalNew = [];
                let reportNew = [];
                let finalApproval = [];
                let finalReport = [];
                _.forEach(values, function(v,i){
                    approvalIssued.push(v.approval_issued.value);
                    reportIssued.push(v.report_issued.value);
                    approvalNew.push(v.approval_new.value);
                    reportNew.push(v.report_new.value);
                });

                if(this.state.type === 1) {
                    finalApproval = approvalNew;
                    finalReport = reportNew;
                }else  {
                    finalApproval = approvalIssued;
                    finalReport = reportIssued;
                }
                this.setState({
                    data: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu',' Sep', 'Okt', 'Nov', 'Des'],
                        approval: {
                            data: finalApproval,
                        },
                        report: {
                            data: finalReport,
                        },
                    },
                }, () => {
                    this.buildChart();
                });
            }
        }).catch(err => {
            console.log(err);
        });

    };

    handleChange (e, prop){

        if(prop === 'type') {
            this.setState({
                type: e.target.value,
            }, () => {
                this.__fetch();
            });
        }else {
            this.setState({
                [prop]: e.target.value,
            });
        }



    };

    setDateRange = (date, prop) => {
        this.setState({
                startDate: date.startDate,
                endDate: date.endDate,
                openDate: false,
        }, () => {
            this.__fetch();
        })
    };

    openDate = (date, prop) => {
        this.setState({
            [prop]: true,
        });
    };

    handleExport = () => {

        this._isMounted = true;

        this.setState({
            loadingButton: true,
        })

        let route = '/projects?export=true';

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {
                    let url = resp.data.url;
                    window.open(url);
                    this.setState({
                        url: resp.data && resp.data.url,
                        loadingButton: false,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingButton: false,
            })
        });

    };

    render() {

        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Operation Performance Charts</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Charts</li>
                        </ol>
                    </nav>
                    <div className='table-wrapper'>
                        <div className='row align-items-center justify-content-end mb-md-3'>
                            <div className="col-md-6 col-lg-4 mr-auto">
                                <div className="form-group">
                                    <label>Pilih Tahun</label>
                                    <TextField variant="outlined"
                                               select
                                               id="year"
                                               name="year"
                                               label="Tahun"
                                               onChange={(e) => this.handleChange(e, 'year')}
                                               value={this.state.year}
                                               fullWidth
                                    >
                                        {this.state.years.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mr-auto">
                                <div className="form-group">
                                    <label>Pilih Tipe</label>
                                    <TextField variant="outlined"
                                               select
                                               id="type"
                                               name="type"
                                               label="Tipe"
                                               onChange={(e) => this.handleChange(e, 'type')}
                                               value={this.state.type}
                                               fullWidth
                                    >

                                        <MenuItem value={1}>
                                            New Instruction
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            Report Issued
                                        </MenuItem>
                                    </TextField>
                                </div>
                            </div>
                            <div className='col-md-4 text-md-right'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='mb-3 mb-md-0'
                                    onClick={() => this.handleExport()}
                                    disabled={this.state.loadingButton}
                                >
                                    Export ALL Projects { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </div>
                        <div className='row'>

                            <div className='col-12'>
                                <div className='myChartDiv'>
                                    <canvas
                                        id="horizontal_bar_chart"
                                    > </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )

    }
}

export default OperationPerformanceCharts;