import moment from "moment";

class CommonHelper {

    static getFileName = (file, defaultName = 'file') => {
        return (file?.name) ? file.name.substr(0, file.name.lastIndexOf('.')) : defaultName;
    }

    static getFileExtension = (file) => {
        return file.name.substr(file.name.lastIndexOf('.') + 1);
    }

    static getFileNameWithTimestamp = (file) => {
        const name = this.getFileName(file);
        const extension = this.getFileExtension(file);

        return `${name}_${moment().format('YYYYMMDDhhmmssSSS')}.${extension}`;
    }

    static mapProjectReportStatusOrder = (data) => {
        const sortingPriority = [
            'Waiting for supporting data',
            'Prelim advise compose by Surveyor',
            'Prelim advise compose by Admin',
            'Prelim advise reviewed by Admin',
            'Prelim advise reviewed by Pak Alif',
            'Prelim advise reviewed by Pak Fauzi',
            'Prelim Advice Issued',
            'Draft Report compose by Surveyor',
            'Draft Report compose by Admin',
            'Draft Report Final being review by Pak Alif',
            'Draft Report Final being review by Pak Fauzi',
            'Report ready to be issue',
            'Report issued',
          ];

        const sorted = data.sort((a, b) => {
            const nameA = a.name.trim();
            const nameB = b.name.trim();
            const indexA = sortingPriority.indexOf(nameA);
            const indexB = sortingPriority.indexOf(nameB);
        
            if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
            }
            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;
        
            return nameA.localeCompare(nameB);
        });

        return sorted;
    }

    static mapProjectApprovalStatusOrder = (data) => {
        const sortingPriority = [
            'Draft review by Surveyor',
            'Draft approval being compose by admin / surveyor',
            'Draft ready to be reviewed by Pak Alif',
            'Final Draft sent to Clients',
            'Approval reviewed by admin',
            'Approval ready to be reviewed by Pak Fauzi',
            'Approval ready to be issued',
            'Approval issued',
          ];
          
        const sorted = data.sort((a, b) => {
            const nameA = a.name.trim();
            const nameB = b.name.trim();
            const indexA = sortingPriority.indexOf(nameA);
            const indexB = sortingPriority.indexOf(nameB);
        
            if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
            }
            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;
        
            return nameA.localeCompare(nameB);
        });

        return sorted;
    }

    static checkPermission = (permission_list = [], name) => {
        let all = permission_list.filter((item) => item === name);
        if (all.length > 0) return true;
        return false;
    };
}

export default CommonHelper;